<template>
  <div id="app" v-if="!loading">
    <header-component></header-component>
    <vue-page-transition name="fade-in-down">
      <router-view />
    </vue-page-transition>
    <footer-component></footer-component>
  </div>
</template>

<script>
import TopHeaderComponent from "./components/TopHeader.vue";
import HeaderComponent from "./components/Header.vue";
import FooterComponent from "./components/Footer.vue";
//import { inject } from "vue";

export default {
  components: { TopHeaderComponent, HeaderComponent, FooterComponent },

  data: function() {
    return {
      DBitems: null,
      loading: false
    };
  },
  created() {
  },
  updated() {}
};
</script>
<style lang="scss">
/*#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}*/
</style>
