<template>
  <div>
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <div class="form-container">
      <form @submit.prevent="submitmessage" @keydown="form.onKeydown($event)">
        <div class="container">
          <div class="row">
            <h2>{{ $t("NinthContactTitle") }}</h2>
            <input
              name="name"
              type="text"
              :placeholder="$t('EnterName')"
              v-model="form.name"
              required
            />
            <input
              name="email"
              type="email"
              :placeholder="$t('Email')"
              v-model="form.email"
              required
            />
            <input
              name="phone"
              type="tel"
              pattern="[\+]?[0-9]*"
              size="20"
              minlength="6"
              maxlength="14"
              :placeholder="$t('Phone')"
              v-model="form.phone"
              required
            />
            <textarea rows="2" :placeholder="$t('message')" v-model="form.message" required></textarea>
            <button type="submit" :disabled="form.busy" class="btn">{{ $t("SendMessage") }}</button>
          </div>
        </div>
      </form>
    </div>
    <div style="position: fixed; width: 500px; bottom: 10px; left: 10px">
      <AlertSuccess :form="form" :message="$t('Message1')" />
      <AlertErrors :form="form" :message="$t('Message0')" />
    </div>
  </div>
</template>
<script>
import Form from "vform";
import { AlertErrors, AlertSuccess } from "vform/src/components/bootstrap5";

export default {
  components: {
    AlertErrors,
    AlertSuccess
  },
  data() {
    return {
      loading: false,
      DBitems: null,

      form: new Form({
        name: null,
        email: null,
        phone: null,
        message: null
      })
    };
  },
  created() {

  },
  methods: {
    updatedata() {
    
    }
  }
};
</script>
<style scoped>
section {
  padding-top: 0px;
  padding-bottom: 80px;
  color: var(--primary-color);
  font-family: "Kanit-Medium";
  text-align: center;
  background-color: var(--primary-color);
}
section h2 {
  font-family: "Kanit-Bold";
  font-size: 2em;
  line-height: 40px;
  background: linear-gradient(
    45deg,
    var(--third-color),
    #fff,
    var(--third-color)
  );
  background-repeat: round;
  background-size: 80%;
  animation: animate 10s linear infinite;
  /* background-clip: text;*/
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

section p {
  /*  width: 80%;*/
  font-family: "Kanit-Medium";
  font-size: 16px;
  line-height: 15px;
  color: #fff;
  margin-top: 15px;
}
.card {
  width: 100%;
  height: 90%;
  border: 0;
  border-radius: 25px;
  padding: 40px 0 20px 0;
  margin-top: 20px;
}
.btn {
  /*border: 2px solid var(--primary-color);*/
  background-color: var(--third-color);
  border-radius: 30px;
  font-size: 18px;
  font-family: "Kanit-Medium";
  color: var(--primary-color);
  font-weight: 700;
  text-decoration: none;
  z-index: 200;
  animation: animateBtn1 1s alternate infinite;
  text-align: center;
}
.btn:hover {
  transform: scale(1.05);
  background-color: var(--third-color);
}
img {
  width: 50px;
  margin-bottom: 20px;
}
.form-container {
  /*background-color: #fff;*/
  border-radius: 25px;
  width: 100%;
}
.contact-container {
  width: 90%;
  margin: 0 5%;
}
.contact-container .container {
  padding: 20px 0;
}
.form-container .container {
  padding: 15px;
}
input {
  border: 1px solid var(--third-color);
  border-radius: 5px;
  width: 90%;
  height: 35px;
  margin: 5px 5%;
}

textarea {
  border: 1px solid var(--third-color);
  border-radius: 5px;
  width: 90%;
  margin: 5px 5%;
}
form .btn {
  border-radius: 25px;
  width: 50%;
  height: 50px;
  margin: 10px 24% 0;
  animation: none;
}

section form h2 {
  font-family: "Kanit-Bold";
  font-size: 2em;
  line-height: 40px;
  background: linear-gradient(
    45deg,
    var(--primary-color),
    #026e8f,
    var(--primary-color)
  );
  background-repeat: round;
  background-size: 80%;
  animation: animate 10s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

.contact-info a {
  margin-top: 100px;
}
.social-linkes {
  margin-top: 30px;
}
.social-linkes img {
  width: 30px;
  height: 30px;
}
.social-linkes a {
  width: 50px;
  display: inline-block;
  border-radius: 25px;
}
.social-linkes a:hover {
  box-shadow: 0 0 15px var(--third-color);
}
@media (max-width: 992px) {
  form .btn {
    width: 30%;
    height: 50px;
    font-size: 0.9em;
    margin: 10px 35% 0;
  }
}

@media (max-width: 1200px) {
}

@media (max-width: 630px) {
}

@media (max-width: 575px) {
  section {
    padding-top: 0px;
    padding-bottom: 20px;
  }
  section h2,
  section form h2 {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 10px !important;
  }
  section h4 {
    font-size: 12px;
    line-height: 30px;
  }
  section p {
    font-size: 12px;
    line-height: 2px;
  }
  input,
  textarea {
    font-size: 11px;
  }
  .social-linkes img {
    width: 20px;
    height: 20px;
  }
  .row {
    margin-top: 10px !important;
  }

  form .btn {
    border-radius: 25px;
    width: 40%;
    margin: 0 30%;
    animation: none;
    font-size: 14px;
  }
  .contact-info a {
    margin-top: 20px;
  }
}

@media (max-width: 400px) {
}
</style>
