<template>
  <div>
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <!-- ======= Second Section ======= -->
    <section style="padding: 0">
      <!--style="margin-top:100px"-->
      <!--<vue-particles color="#dedede" :particleOpacity="0.7" :particlesNumber="50"  :lineLinked="false"  shapeType="edge"></vue-particles>-->
      <div dir="ltr" class="col-lg-12" v-if="!loading">
        <div class="heroCarousel">
          <carousel
            :autoplayTimeout="4000"
            :per-page="1"
            :autoplayHoverPause="false"
            :mouse-drag="false"
            autoplay
            loop
            :paginationEnabled="false"
          >
            <slide>
              <div
                class="container1"
                :style="{
                  backgroundImage: 'url(/assets/image/Can/sliderImage2.jpg)',
                }"
              >
                <!-- + item.image + -->
                <section
                  data-aos="fade"
                  data-aos-duration="3000"
                  class="slider d-flex justify-content-center"
                  id="slider"
                  style="height: 100%"
                >
                  <div class="container text-center">
                    <div class="row all-content">
                      <div class="col-12">
                        <div class="left-content">
                          <!--<p class="hello">
                          {{ $t('Title') }}</p>-->
                          <!-- Animated Text-->
                          <b class="hello"
                            >Dein Lebensmittelgroßhandel für die Gastronomie!</b
                          >
                          <div class="animated-headlines">
                            <h1 class="cd-headline clip is-full-width">
                              <span class="cd-words-wrapper">
                                <p>
                                  Entdecke hochwertige Lebensmittel für Ihr
                                  Restaurant, Café, Hotel oder Catering
                                  Unternehmen. Bei uns findest du ein breites
                                  Sortiment von frischen Zutaten bis hin zu
                                  exklusiven Spezialitäten aus aller Welt.
                                </p>
                                <!--<b>UI/UX Designer</b>-->
                              </span>
                            </h1>
                          </div>
                          <!--<router-link class="btn" dir="auto" 
                          :to="{
                              name: 'createAccount',
                            }">
                            ÜBER UNS
                            <i
                              :class="$i18n.locale == 'ar' ?'bi bi-arrow-left' : 'bi bi-arrow-right'"
                            ></i>
                          </router-link>-->
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </slide>
          </carousel>
        </div>
        <!-- <div class="heroCarousel">
              <carousel
                v-if="sliders.length !=0"
                :per-page="1"
                :mouse-drag="false"
                autoplay
                loop
                :paginationEnabled="false"
              >
                <slide v-for="(item, idx) in sliders" :key="idx">
                  <img :src="item.image" @click="sliderLink(item.value)" />
                </slide>
              </carousel>
              <div v-else > <img src="../../public/assets/image/slide1.jpg" /></div>
        </div>-->
      </div>
    </section>
    <!-- End Second -->
  </div>
</template>
<script>
//import card1 from "../components/CardStyle1.vue";
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      loading: false,
      DBitems: null,
    };
  },
  created() {
    /* this.loading = true;
    //////////////////////home//////////////////////
    this.axios
      .get("https://etrolley.net/api/website/sliders_website", {
        headers: {
          "Accept-Language": this.$i18n.locale
        }
      })
      .then(response => (this.DBitems = response.data.data.sliders))
      .then(() => (this.loading = false));*/
  },
  methods: {
    updatedata() {
      /* this.loading = true;
      //////////////////////home//////////////////////
      this.axios
        .get("https://etrolley.net/api/website/sliders_website", {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => (this.DBitems = response.data.data.sliders))
        .then(() => (this.loading = false));*/
    },
  },
};
</script>
<style scoped>
/* ==========================
   [02]. Slider
========================== */
.container1 {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  aspect-ratio: 2560/1153;
  background-color: #707070;
  background-blend-mode: multiply;
  clip-path: polygon(0% 0%, 100% 0, 100% 85%, 0 100%);
}

img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.heroCarousel {
  /*margin-top: 25px;
   height: 390px;
  width: 100%;
  max-width: 500px;*/
  /*aspect-ratio: 1200 / 300;*/

  border-radius: 15px;
  padding: 0;
}

.slider {
  /* background: url('../img/bg/slider-bg.png') center center no-repeat;*/
  font-family: "Kanit-Medium";
  background-color: transparent;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}

.slider .particels {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.animated-headlines .cd-headline span {
  font-size: 50px;
  font-weight: 700;
  /* text-transform: uppercase;*/
  color: #e0a80d;
}
.slider .left-content {
  margin-top: 80px;
  text-align: start;
}
.slider .left-content .hello {
  font-family: "Kanit-Medium";
  font-size: 2.5em;
  font-weight: 100;
  line-height: 50px;
  color: #fff;
  text-align: start;
}

.slider .left-content h2 {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  padding: 10px 0;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  display: inline-block;
  margin: 5px 0 20px 0;
}

.slider .left-content .paragraph {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 28px;
  padding-right: 20px;
}

.slider .left-content .btn {
  border: 2px solid var(--third-color);
  background-color: transparent;
  border-radius: 30px;
  padding: 10px 8px 10px 30px;
  font-size: 18px;
  font-family: "Kanit-Medium";
  color: #fff;
  font-weight: 700;
  margin-top: 30px;
  /*margin-bottom: 50px;*/
  text-decoration: none;
  z-index: 200;
  animation: animateBtn 0.7s alternate infinite;
}
.slider .left-content .btn:hover {
  transform: scale(1.05);
  color: var(--primary-color) !important;
}
[dir="rtl"] .slider .left-content .btn {
  padding: 10px 30px 10px 8px;
}
.slider .left-content .btn i {
  font-size: 25px;
  background-color: #fff;
  border-radius: 50px;
  padding: 4px 14px;
  margin: 0 0 0 10px;
  color: #000;
}
[dir="rtl"] .slider .left-content .btn i {
  margin: 0 10px 0 0;
}
.cd-headline {
  margin-top: 20px;
}

.cd-headline p {
  position: relative;
  font-family: "Kanit-Medium";
  max-width: 85%;
  font-size: 22px;
  line-height: 35px;
  overflow: hidden;
  color: #fff;
  font-weight: 100;
  /* background: linear-gradient(
    45deg,
    var(--primary-color),
    #026e8f,
    var(--primary-color)
  );
  background-repeat: round;
  background-size: 80%;
  animation: animate 10s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0); */ /* var(--primary-color);*/
}
[dir="rtl"] .cd-headline p {
  animation: animate1 10s linear infinite;
}

/************************************************************************* */
@media (min-width: 1400px) {
  .cd-headline p {
    line-height: 35px !important;
  }
}
@media (max-width: 992px) {
  .container1 {
    padding-bottom: 0 !important;
    aspect-ratio: inherit;
    background-position: center;
  }
  .animated-headlines .cd-headline span {
    font-size: 25px;
  }
  .slider {
    margin-top: 55px;
  }
  .slider .left-content .btn {
    margin-top: 5px;
  }
  .cd-headline p {
    font-size: 15px;
    line-height: 18px;
  }
  .slider .left-content {
    margin-top: 30px;
  }
}

@media (max-width: 630px) {
}

@media (max-width: 575px) {
  section {
    /*margin-top: 40px !important;*/
    padding-bottom: 10px !important;
  }
  .animated-headlines .cd-headline span {
    font-size: 16px;
  }
  .container1 {
    padding-bottom: 0 !important;
    aspect-ratio: inherit;
    background-position: center;
  }
  .slider .left-content .hello {
    font-size: 16px;
    line-height: 15px;
    color: #fff;
    font-family: "Kanit-Bold";
    font-size: 15px;
  }

  .slider .left-content h2 {
    font-size: 16px;
    font-weight: 600;
    padding: 10px 0;
    margin: 5px 0 20px 0;
  }

  .slider .left-content .paragraph {
    font-size: 14px;
    padding-right: 20px;
  }

  .slider .left-content .btn {
    padding: 5px 15px 5px 5px !important;
    font-size: 12px;
    font-weight: 700;
    margin-top: 5px;
  }
  .slider .left-content .btn i {
    font-size: 15px;
    padding: 0 4px;
  }
  .cd-headline p {
    font-size: 12px;
    line-height: 15px;
  }

  .slider .left-content {
    margin-top: 0px;
  }
  .cd-headline {
    margin-top: 5px;
  }
}

@media (max-width: 400px) {
}
</style>
