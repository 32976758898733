<template>
  <div>
    <!--<div v-if="this.$store.state.langchange">{{ updatedata() }}</div>-->
    <!-- ======= Second Section ======= -->
    <section style="margin-top: 120px">
      <div class="container">
        <h4>Worauf sind wir Stolz?</h4>
        <div
          class="d-flex flex-row justify-content-around align-items-center"
          style="min-height: 180px"
        >
          <div class="element" data-aos="fade-zoom-in" data-aos-duration="3000">
            <i class="bi bi-bookmark-star"></i>
            <h2>+ 20</h2>
            <h3>Jahre Erfahrungen</h3>
          </div>
          <div class="element" data-aos="fade-zoom-in" data-aos-duration="3000">
            <i class="bi bi-people"></i>
            <h2>+ 800</h2>
            <h3>Kunden</h3>
          </div>
          <div class="element" data-aos="fade-zoom-in" data-aos-duration="3000">
            <i class="bi bi-box-seam"></i>
            <h2>+ 5.000</h2>
            <h3>Artikel</h3>
          </div>
          <div class="element" data-aos="fade-zoom-in" data-aos-duration="3000">
            <i class="bi bi-truck"></i>
            <h2>+ 20</h2>
            <h3>LKW</h3>
          </div>
        </div>
      </div>
    </section>
    <!-- End Second -->
  </div>
</template>
<script>
//import card1 from "../components/CardStyle1.vue";

export default {
  /*  components: {
    card1,
  },*/
  data() {
    return {};
  },
  created() {
    /*this.loading = true;
    //////////////////////home//////////////////////
    this.axios
      .get(this.$store.state.base_api_url + this.$store.storeName + "/home", {
        headers: {
          "Accept-Language": this.$i18n.locale,
        },
      })
      .then((response) => (this.DBitems = response.data.data.market))
      .then(() => (this.loading = false));*/
  },
  methods: {
    /*updatedata() {
      console.log("hooooooooooooooo");
      this.loading = true;
      //////////////////////home//////////////////////
      this.axios
        .get(this.$store.state.base_api_url + this.$store.storeName + "/home", {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        })
        .then((response) => (this.DBitems = response.data.data.market))
        .then(() => (this.loading = false));

      this.$store.state.langchange = false;
    },*/
  },
};
</script>
<style scoped>
section {
  text-align: center;
  color: #fff;
  font-family: "Kanit-Medium";
  min-height: 380px;

  background-color: #707070;
  /*background-image: url("http://etrolley.net/websiteImages/background/2.jpg");*/
  background-image: url("../../public/assets/image/Can/sliderImage.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-blend-mode: multiply;
}
h2 {
  font-family: "Kanit-Bold";
  color: #fff;
}
h3 {
  font-family: "Kanit-Light";
}
i {
  font-size: 45px;
  color: #fff;
}
section h4 {
  /* width: 80%; */
  font-family: "Kanit-Medium";
  font-size: 2em;
  line-height: 50px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  color: var(--primary-color);
}

@media (max-width: 992px) {
  section {
    min-height: 20px;
    padding: 15px;
    background-position-y: 100px;
    background-size: 250%;
    background-color: #153a57;
    background-image: none;
    background-blend-mode: color;
  }
  section h2 {
    font-size: 15px;
  }
  section h4 {
    font-family: "Kanit-Bold";
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  section h3 {
    text-align: center;
    font-size: 14px;
    line-height: 15px;
    max-width: 100%;
  }
  .element {
    margin-top: 20px;
  }
}

@media (max-width: 1200px) {
}

@media (max-width: 630px) {
}

@media (max-width: 575px) {
  /*section {
    min-height: 20px;
    padding: 15px;
    background-position-y: 110px;
    background-size: 130%;

  }*/
  section .container .d-flex {
    min-height: 20px !important;
    flex-direction: column !important;
  }
  section h2 {
    font-size: 12px;
  }
  section h4 {
    font-family: "Kanit-Bold";
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  section h3 {
    text-align: center;
    font-size: 14px;
    line-height: 17px;
    max-width: 100%;
  }
}

@media (max-width: 400px) {
}
</style>
