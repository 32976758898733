<template>
  <div>
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <!-- ======= Second Section ======= -->
    <section style="margin-top: 0px">
      <!--<div class="container">
        <div
          data-aos="fade-up"
          data-aos-duration="3000"
          class="row"
          style="margin-top: 50px"
        >
          <h4>Über uns</h4>
        </div>
        <div class="row mt-3">
          <div
            class="col-lg-6 col-md-4 col-6"
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-delay="200"
          >
            <div class="container">
              <p>Dein zuverlässiger Partner im Lebensmittelgroßhandel!</p>
              <ul style="list-style: none">
                <li>
                  <i class="ri-check-double-line"></i> Bei CAN steht Qualität an
                  erster Stelle.
                </li>
                <li style="max-width: 80%">
                  <i class="ri-check-double-line"></i> unsere Produkte nicht nur
                  großartig schmecken, sondern auch gut für unseren Planeten
                  sind.
                </li>
                <li style="max-width: 80%">
                  <i class="ri-check-double-line"></i> Wir freuen uns darauf,
                  mit dir zusammenzuarbeiten und Du bei deinem Erfolg zu
                  unterstützen.
                </li>
              </ul>
            </div>
          </div>
          <div
            class="col-lg-6 col-md-4 col-6"
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-delay="200"
          >
            <div class="container">
              <p style="max-width: 80%">
                Wir sind CAN, ein führendes Unternehmen in der
                Lebensmittelbranche seit 2003. Als langjähriger Partner für
                Restaurants, Hotels, Einzelhändler bieten wir eine breite
                Palette von Produkten, die von frischem Obst und Gemüse über
                Fleisch und Meeresfrüchte bis hin zu Spezialitäten aus aller
                Welt reichen. Unser Ziel ist es, hochwertige Lebensmittel
                anzubieten und erstklassigen Kundenservice zu bieten.
              </p>
              <button class="btn">Kontakt</button>
            </div>
          </div>
        </div>
      </div>-->
      <div class="container">
        <div data-aos="fade-up" data-aos-duration="3000" class="row">
          <h4>Möchtest Du eine Bestellung aufgeben?</h4>
          <p>
            Dank unserer herausragenden Mitarbeiter und effizienten Logistik
            sind wir in der Lage, zügig auf Ihre Anforderungen zu reagieren, um
            eine reibungslose und zuverlässige Lieferung sicherzustellen. Du
            bestellst und wir kümmern uns um den Rest. So einfach geht’s!
          </p>
        </div>
        <div class="row mt-3">
          <div class="col-lg-4">
            <div class="card" data-aos="fade-up" data-aos-duration="3000">
              <img src="../../public/assets/image/Can/world-grid_3579187.png" />
              <h4>Online</h4>
              <p>
                Wir haben es für dich noch leichter gemacht. Du kannst jetzt
                ganz einfach online bestellen, um deine Einkäufe so bequem wie
                möglich zu gestalten.
              </p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card" data-aos="fade-up" data-aos-duration="3000">
              <img src="../../public/assets/image/Can/phone-call_4213179.png" />
              <h4>Per Telefon</h4>
              <p>
                Unsere Telefonbestellung ermöglicht Dir eine problemlose und
                schnelle Bestellabwicklung.
              </p>
            </div>
          </div>
          <div class="col-lg-4" data-aos="fade-up" data-aos-duration="3000">
            <div class="card">
              <img src="../../public/assets/image/Can/store_4279632.png" />
              <h4>Vorort</h4>
              <p>
                Du hast weiterhin die Möglichkeit, persönlich bei uns Vorort zu
                bestellen. Wir bieten dir gerne die Gelegenheit, deine
                Bestellungen in unserem Geschäft aufzugeben.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Second -->
  </div>
</template>
<script>
//import card1 from "../components/CardStyle1.vue";

export default {
  /*  components: {
    card1,
  },*/
  data() {
    return {
      loading: false,
      DBitems: null,
    };
  },
  created() {
    /* this.loading = true;
      this.axios
        .get("https://etrolley.net/api/website/services", {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => {
          if (response.data.code == "1") {
            this.DBitems = response.data.data.services;
          }
        })
        .then(() => (this.loading = false));*/
  },
  methods: {
    updatedata() {
      /*this.loading = true;
      this.axios
        .get("https://etrolley.net/api/website/services", {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => {
          if (response.data.code == "1") {
            this.DBitems = response.data.data.services;
          }
        })
        .then(() => (this.loading = false));
      //this.$store.state.langchange1 = false;*/
    },
  },
};
</script>
<style scoped>
section {
  padding-top: 120px;
  padding-bottom: 30px;
  color: var(--primary-color);
  font-family: "Kanit-Medium";
  text-align: start;
}
section h2 {
  font-family: "Kanit-Bold";
  font-size: 2em;
  line-height: 50px;
  background: linear-gradient(
    45deg,
    var(--primary-color),
    #026e8f,
    var(--primary-color)
  );
  background-repeat: round;
  background-size: 80%;
  animation: animate 10s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}
section h4 {
  /* width: 80%; */
  font-family: "Kanit-Medium";
  font-size: 2em;
  line-height: 50px;
  text-align: center;
}
h5 {
  margin-top: 20px;
  color: var(--third-color);
  font-family: "Kanit-Medium";
  font-size: 1.3em;
  line-height: 50px;
  text-align: center;
}
section .card h4 {
  color: var(--primary-color);
  font-family: "Kanit-Bold";
  height: 30px;
  font-size: 18px !important;
  line-height: 32px;
  text-align: center;
}
[dir="ltr"] section .card h4 {
  font-size: 15px;
}

section p {
  max-width: 90%;
  margin: 10px auto;
}
section .card p {
  font-size: 15px;
  line-height: initial;
  margin-bottom: 10px;
}
section p,
section li {
  /*  width: 80%;*/
  font-family: "Kanit-Light";
  font-size: 20px;
  line-height: 25px;
  color: var(--secondary-color);
  text-align: center;
}

section li i {
  color: var(--primary-color);
}
.card {
  width: 100%;
  height: 90%;
  border: 0;
  border-radius: 25px;
  padding: 40px 0 20px 0;
  margin-top: 20px;
}

img {
  width: 50px;
  margin: 10px auto;
}

i {
  color: #000;
  font-size: 35px;
  margin: 10px auto;
  margin-bottom: 20px;
}

.btn {
  border: 2px solid var(--third-color);
  background-color: transparent;
  border-radius: 30px;
  font-size: 18px;
  font-family: "Kanit-Medium";
  color: var(--primary-color);
  font-weight: 700;
  text-decoration: none;
  z-index: 200;
  animation: animateBtn1 1s alternate infinite;
  text-align: center;
  margin-top: 20px;
  min-width: 150px;
}
.btn:hover {
  background-color: var(--third-color);
  color: #fff;
}
@media (max-width: 992px) {
  section h4 {
    font-family: "Kanit-Bold";
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  section p,
  section li {
    text-align: center;
    font-size: 14px;
    line-height: 15px;
    max-width: 100%;
  }
}

@media (max-width: 1200px) {
}

@media (max-width: 630px) {
}

@media (max-width: 575px) {
  section {
    padding-top: 0px;
    padding-bottom: 20px;
  }
  section h2 {
    font-family: "Kanit-Bold";
    font-size: 15px;
    line-height: initial;
  }
  section h4 {
    font-family: "Kanit-Bold";
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .row {
    width: 96%;
    margin: auto;
  }

  section .card h4 {
    height: 35px;
    font-size: 10px;
    line-height: 20px;
    font-weight: 600;
  }
  section p {
    text-align: center;
    font-size: 14px;
    line-height: 15px;
    max-width: 100%;
  }
  .card {
    width: 100%;
    height: 90%;
    border: 0;
    border-radius: 25px;
    padding: 20px 0 10px 0;
    margin-top: 15px;
  }

  img {
    width: 30px;
    margin-bottom: 15px;
  }
}

@media (max-width: 400px) {
}
</style>
