<template>
  <div id="#Kontakt" class="Kontakt">
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <!-- ======= Second Section ======= -->
    <section style="margin-top: 0px">
      <div class="container">
        <div style="margin-top: 20px; margin-bottom: 50px">
          <h4>Kontakt</h4>
          <p>
            Wir freuen uns darauf, mit dir zusammenzuarbeiten und Du bei deinem
            Erfolg zu unterstützen. Kontaktiere uns jetzt!
          </p>
          <!--<h2>{{ $t('SeventhDescription') }}</h2>
          <h2>{{ $t('SeventhSubDescription') }}</h2>-->
        </div>
        <!--<div class="tap-container d-flex flex-row justify-content-around">
          <p>{{ $t('save') }} 20%</p>
          <h4>{{ $t('yearly') }}</h4>
          <label class="switch">
            <input type="checkbox" v-model="monthly" />
            <span class="slider round"></span>
          </label>
          <h4>{{ $t('monthly') }}</h4>
        </div>-->
        <div>
          <div class="row">
            <div class="col-lg-4 col-md-12 d-flex align-items-stretch">
              <div class="info">
                <div class="address">
                  <i class="bi bi-geo-alt"></i>
                  <!--<h4>Location:</h4>-->
                  <p>Gustav Hagemann Straße 46, 38229 Salzgitter</p>
                </div>
                <!--<iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4743.970814458517!2d10.35808028001186!3d52.160344334216504!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a550ad58b244cf%3A0xa305b612d3669ddf!2sCan%20wholesale%20GmbH%20%26%20Co%20KG!5e0!3m2!1sar!2sus!4v1693653222180!5m2!1sar!2sus"
                  frameborder="0"
                  style="border: 0; width: 100%; height: 290px"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>-->
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="info">
                <div class="email">
                  <i class="bi bi-envelope"></i>
                  <!--<h4>Email:</h4>-->
                  <p>info@can-grosshandel.de</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="info">
                <div class="phone">
                  <i class="bi bi-phone"></i>
                  <!--<h4>Call:</h4>-->
                  <p>(+49) 53 41 – 79 06 86 0</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Second -->
  </div>
</template>
<script>
import priceCard from "./seventhCard.vue";
import priceCard2 from "./seventhCardStyle2.vue";

export default {
  components: {
    priceCard,
    priceCard2,
  },
  data() {
    return {
      loading: false,
      DBitemsMonthly: null,
      DBitemsYearly: null,

      monthly: false,
      features: Array(
        "3 جيجابايت مساتحة تخزبن",
        "دعم فني",
        "ربط النطاق الخاص بك",
        "لغة أساسية + لغة إضافية"
      ),
    };
  },
  created() {},
  watch: {
    monthly() {
      this.updatedata();
    },
  },
  methods: {},
};
</script>
<style scoped>
.info {
  border-radius: 15px;
  padding: 20px 30px 2px;
  background: #fff;
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  height: 90%;
  margin-bottom: 20px;
}
.info i {
  font-size: 20px;
  color: var(--third-color);
  float: left;
  width: 44px;
  height: 44px;
  background: #e7f5fb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}
.info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #37517e;
  text-align: start;
}

.info p {
  padding: 10px 0 0 45px;
  margin-bottom: 10px;
  font-size: 14px;
  color: var(--secondary-color);
  text-align: start;
}
.php-email-form label {
  padding-bottom: 8px;
}
.php-email-form {
  width: 100%;
  border-top: 3px solid var(--third-color);
  border-bottom: 3px solid var(--third-color);
  padding: 30px;
  background: #fff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}
.php-email-form input,
.php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}
section {
  padding-top: 100px;
  padding-bottom: 150px;
  color: var(--secondary-color);
  font-family: "Kanit-Medium";
  text-align: start;
  margin-top: 0px;
}
section h4 {
  /* width: 80%; */
  font-family: "Kanit-Medium";
  font-size: 2em;
  color: var(--primary-color);
  line-height: 50px;
  text-align: center;
}
section .card h4 {
  height: 70px;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
}
section p {
  /*  width: 80%;*/
  font-family: "Kanit-Light";
  font-size: 18px;
  line-height: 25px;
  max-width: 90%;
  color: var(--secondary-color);
  text-align: center;
  margin: auto;
}

.card {
  width: 100%;
  height: 90%;
  border: 0;
  border-radius: 25px;
  padding: 40px 0 20px 0;
  margin-top: 20px;
}
.btn {
  border: 2px solid var(--third-color);
  background-color: transparent;
  border-radius: 30px;
  font-size: 18px;
  font-family: "Kanit-Medium";
  color: var(--primary-color);
  font-weight: 700;
  text-decoration: none;
  z-index: 200;
  text-align: center;
  margin-top: 20px;
}
.btn:hover {
  background-color: var(--third-color);
  color: #fff;
}
[dir="rtl"] .btn {
  padding: 10px 30px 10px 8px;
}
.btn i {
  font-size: 25px;
  background-color: #ffe9c8;
  border-radius: 50px;
  padding: 4px 14px;
  margin: 0 0 0 10px;
}
[dir="rtl"] .btn i {
  margin: 0 10px 0 0;
}
img {
  width: 50px;
  margin-bottom: 20px;
}

@media (max-width: 992px) {
  .tap-container {
    width: 60%;
    margin: 20px 20% 0;
  }
  section h4 {
    font-family: "Kanit-Bold";
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  section p {
    text-align: center;
    font-size: 14px;
    line-height: 15px;
    max-width: 100%;
  }
}

@media (max-width: 1200px) {
}

@media (max-width: 630px) {
}

@media (max-width: 575px) {
  section {
    padding-top: 0px;
    padding-bottom: 20px;
    margin-top: 20px !important;
    border-top-left-radius: 50px 50px;
    border-top-right-radius: 50px 50px;
  }
  section h4 {
    font-family: "Kanit-Bold";
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  section p {
    text-align: center;
    font-size: 14px;
    line-height: 15px;
    max-width: 100%;
  }
  .row {
    width: 96%;
    margin: 10px auto !important;
  }
  .tap-container {
    width: 60%;
    margin: 20px 20% 0;
  }
  .tap-container p {
    background-color: #fcdc00;
    border-radius: 25px;
    font-size: 10px;
    padding: 5px 5px;
  }
  section .tap-container h4 {
    font-size: 10px;
  }

  .switch {
    width: 40px;
    height: 24px;
  }

  .slider:before {
    height: 16px;
    width: 16px;
    left: 4px;
  }

  [dir="rtl"] .slider:before {
    left: 20px;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  [dir="rtl"] input:checked + .slider:before {
    -webkit-transform: translateX(-16px);
    -ms-transform: translateX(-16px);
    transform: translateX(-16px);
  }
}

@media (max-width: 400px) {
}
</style>
