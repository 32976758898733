<template>
  <section id="Questions">
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <!--<div v-if="!loading" class="container">
      <h3>{{ $t('questions') }}</h3>
      <div class="row" style="margin-top:50px">
        <div v-for="(item,index) in DBitems" :key="index">
          <div class="card">
            <div class="container">
              <h4>
                <i class="bi bi-check"></i>
                {{ item.question }}
              </h4>
              <div class="container">
                <h6>{{ item.replay }}</h6>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>-->
    <div v-if="!loading" class="container">
      <div class="container">
        <h3>IMPRESSUM</h3>
        <div class="row">
          <div
            class="col-lg-5"
            data-animation="fadeIn"
            data-parallax-speed="0"
            style=""
          >
            <div class="tatsu-column-inner">
              <div class="tatsu-column-pad-wrap">
                <div class="tatsu-column-pad">
                  <div
                    class="tatsu-module tatsu-text-block-wrap tatsu-fpqonkrt624ttzdg"
                  >
                    <div
                      class="tatsu-text-inner tatsu-align-center clearfix"
                      data-animation="fadeIn"
                    >
                      <h4><strong>Angaben gemäß § 5 TMG:</strong></h4>
                      <p>
                        CAN Großhandel GmbH &amp; Co. KG<br />
                        Gustav Hagemann Straße 46
                      </p>
                      <p>38229 Salzgitter</p>
                      <h4><strong>Vertreten durch:</strong></h4>
                      <p>
                        Herr Özgür Sarikaya<br />
                        Herr Ekin Sarikaya
                      </p>
                      <h4><strong>Kontakt:</strong></h4>
                      <p>
                        Telefon: +49 (0) 53 41 – 790 686 0<br />
                        Telefax: +49 (0) 53 41 – 790 686 3<br />
                        E-Mail:&nbsp; info@can-grosshandel.de
                      </p>
                      <h4><strong>Registereintrag:</strong></h4>
                      <p>
                        Eintragung im Handelsregister.<br />
                        Registergericht:Amtsgericht Braunschweig<br />
                        Registernummer: HRB 200518
                      </p>
                      <h4><strong>Umsatzsteuer:</strong></h4>
                      <p>
                        Umsatzsteuer-Identifikationsnummer gemäß §27 a<br />
                        Umsatzsteuergesetz: DE 251 914 530<br />
                        Steuernummer: 51/202/06707
                      </p>
                      <h4><strong>Streitschlichtung</strong></h4>
                      <p>
                        Wir sind nicht bereit oder verpflichtet, an
                        Streitbeilegungsverfahren vor einer
                        Verbraucherschlichtungsstelle teilzunehmen.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tatsu-column-bg-image-wrap">
                <div class="tatsu-column-bg-image"></div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-7"
            data-animation="fadeIn"
            data-parallax-speed="0"
            style=""
          >
            <div class="tatsu-column-inner">
              <div class="tatsu-column-pad-wrap">
                <div class="tatsu-column-pad">
                  <div
                    class="tatsu-module tatsu-text-block-wrap tatsu-fpqonkrt7q8njj7o"
                  >
                    <div
                      class="tatsu-text-inner tatsu-align-center clearfix"
                      data-animation="fadeIn"
                    >
                      <h4><strong>Haftung für Inhalte</strong></h4>
                      <p>
                        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für
                        eigene Inhalte auf diesen Seiten nach den allgemeinen
                        Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir
                        als Diensteanbieter jedoch nicht verpflichtet,
                        übermittelte oder gespeicherte fremde Informationen zu
                        überwachen oder nach Umständen zu forschen, die auf eine
                        rechtswidrige Tätigkeit hinweisen.
                      </p>
                      <p>
                        Verpflichtungen zur Entfernung oder Sperrung der Nutzung
                        von Informationen nach den allgemeinen Gesetzen bleiben
                        hiervon unberührt. Eine diesbezügliche Haftung ist
                        jedoch erst ab dem Zeitpunkt der Kenntnis einer
                        konkreten Rechtsverletzung möglich. Bei Bekanntwerden
                        von entsprechenden Rechtsverletzungen werden wir diese
                        Inhalte umgehend entfernen.
                      </p>
                      <h4><strong>Haftung für Links</strong></h4>
                      <p>
                        Unser Angebot enthält Links zu externen Webseiten
                        Dritter, auf deren Inhalte wir keinen Einfluss haben.
                        Deshalb können wir für diese fremden Inhalte auch keine
                        Gewähr übernehmen. Für die Inhalte der verlinkten Seiten
                        ist stets der jeweilige Anbieter oder Betreiber der
                        Seiten verantwortlich.<br />Die verlinkten Seiten wurden
                        zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
                        überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                        Verlinkung nicht erkennbar.
                      </p>
                      <p>
                        Eine permanente inhaltliche Kontrolle der verlinkten
                        Seiten ist jedoch ohne konkrete Anhaltspunkte einer
                        Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
                        Rechtsverletzungen werden wir derartige Links umgehend
                        entfernen.
                      </p>
                      <h4><strong>Urheberrecht</strong></h4>
                      <p>
                        Die durch die Seitenbetreiber erstellten Inhalte und
                        Werke auf diesen Seiten unterliegen dem deutschen
                        Urheberrecht. Die Vervielfältigung, Bearbeitung,
                        Verbreitung und jede Art der Verwertung außerhalb der
                        Grenzen des Urheberrechtes bedürfen der schriftlichen
                        Zustimmung des jeweiligen Autors bzw. Erstellers.
                        Downloads und Kopien dieser Seite sind nur für den
                        privaten, nicht kommerziellen Gebrauch gestattet.
                      </p>
                      <p>
                        Soweit die Inhalte auf dieser Seite nicht vom Betreiber
                        erstellt wurden, werden die Urheberrechte Dritter
                        beachtet.<br />Insbesondere werden Inhalte Dritter als
                        solche gekennzeichnet. Sollten Sie trotzdem auf eine
                        Urheberrechtsverletzung aufmerksam werden, bitten wir um
                        einen entsprechenden Hinweis. Bei Bekanntwerden von
                        Rechtsverletzungen werden wir derartige Inhalte umgehend
                        entfernen.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        Quelle:
                        <a href="https://www.e-recht24.de">e-recht24.de</a
                        ><br />Quellenangaben für die verwendeten Bilder und
                        Grafiken:<br /><a href="https://www.shutterstock.com/"
                          >https://www.shutterstock.com/</a
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tatsu-column-bg-image-wrap">
                <div class="tatsu-column-bg-image"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import VueFaqAccordion from "vue-faq-accordion";
export default {
  components: {
    VueFaqAccordion,
  },
  data: function () {
    return {
      loading: false,
      DBitems: null,
      myItems: [
        {
          title: "How many time zones are there in all?",
          value:
            "Given a 24-hour day and 360 degrees of longitude around the Earth",
        },
        {
          title: "How long is a day and year on Venus?",
          value:
            "Venus takes 224.7 Earth days to complete one orbit around the Sun.",
        },
        {
          title: "What animal smells like popcorn?",
          value: "Binturongs smell like popcorn.",
        },
      ],
    };
  },
  created() {
    /* this.loading = true;
    this.axios
      .get("https://etrolley.net/api/website/questions", {
        headers: {
          "Accept-Language": this.$i18n.locale
        }
      })
      .then(response => {
        this.DBitems = response.data.data.questions;
      })
      .then(() => (this.loading = false));*/
  },
  methods: {},
};
</script>

<style scoped>
#Questions {
  padding-top: 150px;
  min-height: 600px;
}
[dir="ltr"] .card {
  border-left: 3px solid var(--primary-color);
  border-radius: 0px;
  padding: 10px;
  margin-bottom: 20px;
  height: 90%;
}
[dir="rtl"] .card {
  border-right: 3px solid var(--primary-color);
  border-radius: 0px;
  padding: 10px;
  margin-bottom: 20px;
  height: 90%;
}
i {
  color: darkgreen;
}
h4 {
  color: var(--secondary-color);
  font-size: 15px;
}
h6 {
  color: #6f6e6e;
  width: 90%;
  margin: 20px 5% 40px;
  height: 75px;
  overflow: auto;
}
section h3 {
  color: var(--primary-color);
  margin-top: 20px;
  margin-bottom: 20px;
}
/*.faq-wrapper{
    max-width: 825px;
}*/
@media (max-width: 992px) {
  #Questions {
    padding-top: 85px;
    min-height: 600px;
  }
}
</style>

