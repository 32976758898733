<template>
  <div id="tenth">
    <!--<div v-if="this.$store.state.langchange">{{ updatedata() }}</div>-->
    <!-- ======= Second Section ======= -->
    <section style="margin-top: 0px">
      <div class="container">
        <div style="margin-top: 50px">
          <h4>Unsere Eigenmarken</h4>
          <div class="container1">
            <!--<p>{{ $t("TenthDescription") }}</p>-->
          </div>
        </div>
        <div>
          <hooper
            style="
              height: 100%;
              margin-top: 20px;
              padding-top: 0px;
              padding-bottom: 0px;
            "
            :rtl="$i18n.locale == 'ar' ? true : false"
            :settings="hooperSettings"
          >
            <slide style="padding: 10px">
              <img src="../../public/assets/image/Can/Priims.jpg" />
            </slide>
            <slide style="padding: 10px">
              <img src="../../public/assets/image/Can/don_marco.png" />
            </slide>
            <slide style="padding: 10px">
              <img src="../../public/assets/image/Can/marke_aycan.png" />
            </slide>
            <slide style="padding: 10px">
              <img src="../../public/assets/image/Can/maxifritt.jpg" />
            </slide>
            <slide style="padding: 10px">
              <img src="../../public/assets/image/Can/marke_domino.png" />
            </slide>
          </hooper>
        </div>
      </div>
    </section>
    <!-- End Second -->
  </div>
</template>
<script>
import storeCard from "./storeCard.vue";
import {
  Hooper,
  Slide,
  Progress as HooperProgress,
  Pagination as HooperPagination,
  Navigation as HooperNavigation,
} from "hooper";
import "hooper/dist/hooper.css";

export default {
  components: {
    Hooper,
    Slide,
    HooperProgress,
    HooperPagination,
    HooperNavigation,
    storeCard,
  },
  data() {
    return {
      hooperSettings: {
        itemsToShow: 3,
        initialSlide: 0,
        itemsToSlide: 1,
        infiniteScroll: true,
        autoPlay: true,
        playSpeed: 3000,
        trimWhiteSpace: true,
        playSpeed: 2000,
        transition: 300,
        centerMode: true,
        pagination: "fraction",
        wheelControl: false,

        breakpoints: {
          768: {
            itemsToShow: 3,
          },
          992: {
            itemsToShow: 4,
          },
          1000: {
            itemsToShow: 5,
          },
        },
      },
    };
  },
  created() {
    /*this.loading = true;
    //////////////////////home//////////////////////
    this.axios
      .get(this.$store.state.base_api_url + this.$store.storeName + "/home", {
        headers: {
          "Accept-Language": this.$i18n.locale,
        },
      })
      .then((response) => (this.DBitems = response.data.data.market))
      .then(() => (this.loading = false));*/
  },
  methods: {
    /*updatedata() {
      console.log("hooooooooooooooo");
      this.loading = true;
      //////////////////////home//////////////////////
      this.axios
        .get(this.$store.state.base_api_url + this.$store.storeName + "/home", {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        })
        .then((response) => (this.DBitems = response.data.data.market))
        .then(() => (this.loading = false));

      this.$store.state.langchange = false;
    },*/
  },
};
</script>
<style scoped>
section {
  padding-top: 120px;
  padding-bottom: 20px;
  color: var(--primary-color);
  font-family: "Kanit-Medium";
  text-align: center;
}
section h2 {
  font-family: "Kanit-Bold";
  font-size: 2em;
  line-height: 40px;
  background: linear-gradient(
    45deg,
    var(--primary-color),
    #026e8f,
    var(--primary-color)
  );
  background-repeat: round;
  background-size: 80%;
  animation: animate 10s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}
section h4 {
  /* width: 80%; */
  font-family: "Kanit-Medium";
  font-size: 2em;

  line-height: 50px;
  text-align: center;
}
section .card h4 {
  height: 70px;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
}
section p {
  /*  width: 80%;*/
  font-family: "Kanit-Light";
  font-size: 18px;
  line-height: 25px;
  max-width: 90%;
  font-weight: 700;
  color: var(--secondary-color);
  text-align: center;
}

.card {
  width: 100%;
  height: 90%;
  border: 0;
  border-radius: 25px;
  padding: 40px 0 20px 0;
  margin-top: 20px;
}
img {
  max-width: 150px;
  margin-bottom: 20px;
}

.container1 {
  width: 50%;
  margin: auto;
}
@media (max-width: 992px) {
  section h4 {
    font-family: "Kanit-Bold";
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  section p {
    text-align: center;
    font-size: 14px;
    line-height: 15px;
    max-width: 100%;
  }
}

@media (max-width: 1200px) {
}

@media (max-width: 630px) {
}

@media (max-width: 575px) {
  section {
    padding-top: 0px;
    padding-bottom: 20px;
    margin-top: 0 !important;
  }
  section h2 {
    font-family: "Kanit-Bold";
    font-size: 15px;
    line-height: 20px;
  }
  section h4 {
    font-family: "Kanit-Bold";
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  section p {
    text-align: center;
    font-size: 14px;
    line-height: 15px;
    max-width: 100%;
  }
  .container1 {
    width: 96% !important;
    margin: auto;
  }
  img {
    width: 70px;
    margin-bottom: 20px;
  }
}

@media (max-width: 400px) {
}
</style>
