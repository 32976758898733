import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        storeName: "sara_market9",
        unit: "",
        unitAR: "",
        cartCounter: 0,
        tax: null,
        //OrgilnalNameAr: "",
        //OrgilnalNameEn: "",
        logo: "",
        logo_footer: "",
        langchange: false,
        langchange1: false,
        userTokenState: false,
        //base_api_url: "http://192.168.1.16:8000/api/"
        base_api_url: "https://etrolley.net/api/"
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {}
})