var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('footer',{attrs:{"id":"footer"}},[_c('div',{staticClass:"container mt-3"},[_c('div',{staticClass:"row footer-width",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"col-lg-4 col-12 mb-1",staticStyle:{"margin-top":"30px"}},[_c('div',[_c('div',[_c('h2',{staticClass:"mb-4"},[_vm._v("CAN Großhandel GmbH & Co. KG")]),_c('router-link',{attrs:{"to":{
                name: 'home',
              }}},[_c('p',[_vm._v(_vm._s(_vm.$t("home")))])]),_c('router-link',{attrs:{"to":{
                    name: 'home',
                  }}},[_c('p',[_vm._v("Produkte")])]),_c('router-link',{attrs:{"to":{
                name: 'about',
              }}},[_c('p',[_vm._v("Über uns")])]),_c('router-link',{attrs:{"to":{
                name: 'DATENSCHUTZERKLARUNG',
              }}},[_c('p',[_vm._v("Datenschutzerklärung")])]),_c('router-link',{attrs:{"to":{
                    name: 'IMPRESSUM',
                  }}},[_c('p',[_vm._v("Impressum")])])],1)])]),_c('div',{staticClass:"col-lg-3 col-6 mb-1",staticStyle:{"margin-top":"30px"}},[_c('div',[_c('div',{staticClass:"contact-info"},[_c('h2',{staticClass:"mb-4"},[_vm._v("Kontakt")]),_vm._m(0),_vm._m(1),_c('a',{attrs:{"href":'mailto:info@can-grosshandel.de'}},[_vm._m(2)]),(!_vm.loading)?_c('h1',[_vm._m(3)]):_vm._e()])])]),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"phoneNumber"},[_c('p',{attrs:{"dir":"ltr"}},[_c('i',{staticClass:"bi bi-phone",attrs:{"data-v-504c80e1":""}}),_vm._v("(+49) 53 41 - 79 06 86 0")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"phoneNumber"},[_c('p',{attrs:{"dir":"ltr"}},[_c('i',{staticClass:"bi bi-printer",attrs:{"data-v-504c80e1":""}}),_vm._v("(+49) 53 41 - 79 06 86 3")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('i',{staticClass:"bi bi-envelope",attrs:{"data-v-504c80e1":""}}),_vm._v("info@can-grosshandel.de")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('i',{staticClass:"bi bi-geo-alt",attrs:{"data-v-504c80e1":""}}),_vm._v("Gustav Hagemann Straße 46, 38229 Salzgitter")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3 col-6 mb-1",staticStyle:{"margin-top":"30px"}},[_c('div',[_c('div',{staticClass:"contact-info"},[_c('h2',{staticClass:"mb-4"},[_vm._v("ÖFFNUNGSZEITEN")]),_c('h1',{staticClass:"phoneNumber"},[_c('p',{attrs:{"dir":"ltr"}},[_vm._v("Öffnungszeiten Großhandel:")])]),_c('h1',{staticClass:"phoneNumber"},[_c('p',{attrs:{"dir":"ltr"}},[_vm._v("Mo –Fr 06.30–17.00 Uhr")])]),_c('h1',{staticClass:"phoneNumber"},[_c('p',{attrs:{"dir":"ltr"}},[_vm._v("Samstag 06.30–14.00 Uhr")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container mobile-active"},[_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-2 col-12 mb-1",staticStyle:{"margin-top":"30px"}},[_c('div',[_c('div',{staticClass:"contact-info"},[_c('h2',{staticClass:"mb-4"},[_vm._v("Folge uns")]),_c('div',{staticClass:"mt-1 social-linkes"},[_c('a',{attrs:{"href":"https://instagram.com/can_grosshandel?igshid=MzRlODBiNWFlZA=="}},[_c('i',{staticClass:"bi bi-instagram"})]),_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"bi bi-facebook"})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-12 col-12"},[_c('div',{staticClass:"copyRight text-center",staticStyle:{"margin-top":"20px"}},[_c('p',{attrs:{"dir":"ltr"}},[_vm._v("Copyright CAN Großhandel GmbH & Co. KG in 2020. All Rights Reserved ")])])])
}]

export { render, staticRenderFns }