<template>
  <div id="About">
    <section style="padding-top: 0">
      <div dir="ltr" class="col-lg-12" v-if="!loading">
        <div class="heroCarousel">
          <carousel
            :autoplayTimeout="4000"
            :per-page="1"
            :autoplayHoverPause="false"
            :mouse-drag="false"
            autoplay
            loop
            :paginationEnabled="false"
          >
            <slide>
              <div
                class="container1"
                :style="{
                  backgroundImage: 'url(/assets/image/Can/sliderImage.jpg)',
                }"
              >
                <!-- + item.image + -->
                <section
                  data-aos="fade"
                  data-aos-duration="3000"
                  class="slider d-flex justify-content-center"
                  id="slider"
                  style="height: 100%"
                >
                  <div class="container text-center">
                    <div class="row all-content">
                      <div class="col-12">
                        <div class="left-content">
                          <b class="hello">EINE STARKE FAMILIE</b>
                          <div class="animated-headlines">
                            <h1 class="cd-headline clip is-full-width">
                              <span class="cd-words-wrapper">
                                <p>
                                  Als Familienunternehmen CAN gehören wir heute
                                  zu den führenden Unternehmen in der
                                  Lebensmittelbranche.
                                </p>
                                <p>
                                  Unsere Wurzeln liegen im
                                  Lebensmittelgroßhandel sowie in der
                                  Lebensmittellogistik. Im Laufe Jahrzehnte hat
                                  sich CAN immer wieder erfolgreich auf die
                                  wechselnden Anforderungen des
                                  Lebensmittelmarktes eingestellt und ist an
                                  diesen Herausforderungen gewachsen. Heute sind
                                  wir ein ganzheitlicher Lebensmittelgroßhandel.
                                </p>
                                <!--<b>UI/UX Designer</b>-->
                              </span>
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </slide>
          </carousel>
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <h4>Werte</h4>
        <p>
          Unsere Werte sind das Fundament unseres unternehmerischen und sozialen
          Handelns – im Umgang mit Kunden, Partnern und unseren engagierten
          Mitarbeitern. Wir setzen auf offene und transparente Kommunikation, um
          gemeinsam die besten Lösungen zu entwickeln. Nur so können wir den
          ständig wachsenden Marktansprüchen gerecht werden und uns
          kontinuierlich weiterentwickeln.
        </p>
        <p>
          Unsere Mitarbeiter sind der Kern unseres Erfolgs. Wir schätzen unsere
          Angestellten als wertvolle Ressource und behandeln sie stets mit
          Respekt und Anerkennung. Wir fördern sowohl die persönliche
          Entwicklung als auch die beruflichen Perspektiven aller
          Teammitglieder.
        </p>
        <p>
          Wir sind auf die Zukunft ausgerichtet. Wir wollen nicht nur heute
          erfolgreich sein, sondern auch morgen einen positiven Einfluss auf
          Kunden, Partner und Mitarbeiter haben.
        </p>
      </div>
    </section>
      <sixth></sixth>
      <team></team>
    <section
      style="margin-top: 20px"
    >
      <div class="container">
        <h4>Tradition und Wachstum in der Lebensmittelbranche</h4>
        <p>
          In den vergangenen Jahrzehnten hat sich CAN immer wieder erfolgreich
          auf die wechselnden Anforderungen des Lebensmittelmarktes eingestellt
          und ist an diesen Herausforderungen gewachsen – vom lokalen
          Lebensmittelgroßhandel zu einem der führenden Anbieter in der
          Lebensmittelbranche mit dem Fokus auf Qualität und
          Kundenzufriedenheit. So können wir auch heute für die Anforderungen
          von morgen sorgen.
        </p>
      </div>
      <div class="container" style="position: relative; margin-top: 100px">
        <div class="timeline images-squared">
          <about-card
            :id="1"
            name="Aktueller Stand"
            image="/assets/image/Can/halle_neu2.jpg"
            description="Heute arbeiten 45 Angestellte im
Unternehmen. Mit unseren 14 Lkw wird
der ständig wachsende Kundenstamm
beliefert."
            year="2023"
            :isRight="false"
          ></about-card>
          <about-card
            :id="2"
            name="Ausbau"
            image="/assets/image/Can/sliderImage.jpg"
            description="Im Jahr 2018 wurde eine weitere Halle
mit 1.800 m² Verkaufsfläche auf das
2013 erworbene Grundstück in der
Gustav-Hagemann-Straße 54
fertiggestellt."
            year="2018"
            :isRight="true"
          ></about-card>
          <about-card
            :id="3"
            name="Neuer Geschäftsführer "
            image="/assets/image/Can/sliderImage2.jpg"
            description="Im Dezember 2017 wurde der Sohn von
Herrn Ali Can Sarikaya, Herr Ekin Sarikaya,
als weiterer Geschäftsführer des
Unternehmens eingetragen."
            year="2017"
            :isRight="false"
          ></about-card>
          <about-card
            :id="4"
            name="Ausbau"
            image="/assets/image/Can/halle_neu2.jpg"
            description="Die ständig wachsende Anzahl der
Mitarbeiter machte 2015 eine
Büroerweiterung erforderlich."
            year="2015"
            :isRight="true"
          ></about-card>
          <about-card
            :id="5"
            name="Von 800m² auf 2.000m²"
            image="/assets/image/Can/sliderImage.jpg"
            description="Schnell wurden die Räumlichkeiten zu
klein, und man erwarb das benachbarte
Grundstück. Im Jahr 2012 erfolgte dann
der Anbau einer 2.000m² großen Halle.
Diese Halle beherbergt ein modernes
Hochregallager und ein 300 m² großes
Tief- und Kühllager. 
"
            year="2012"
            :isRight="false"
          ></about-card>
          <about-card
            :id="6"
            name="Grundstein"
            image="/assets/image/Can/sliderImage2.jpg"
            description="Herr Özgür Sarikaya gründete 2007 mit den
Kindern von Ali Can Sarikaya als
Gesellschafter eine neue Firma, und aus
CAN Einzelhandel entstand das
Familienunternehmen CAN Großhandel
GmbH & Co. KG mit 10 Angestellten."
            year="2004"
            :isRight="true"
          ></about-card>
        </div>
      </div>
      <div class="container" style="margin-bottom: 150px; margin-top: 70px">
        <h4>Ali Can Sarikaya</h4>
        <h4 style="margin-top: 20px">
          UNTERNEHMENSGRÜNDER UND GESCHÄFTSFÜHRER BIS 2017
        </h4>
        <p style="margin-top: 20px">
          Im Jahr 2003 wurde im Gewerbegebiet Salzgitter-Engelnstedt ein
          Grundstück gekauft und mit dem Bau eines 800m² großen Verkaufsgebäudes
          begonnen. Die feierliche Eröffnung der Firmenniederlassung in der
          Gustav-HagemannStraße 46 erfolgte im darauf folgenden Jahr.
        </p>
      </div>
    </section>
  </div>
</template>
<script>
import { Carousel, Slide } from "vue-carousel";

import sixth from "../components/sixth.vue";
import team from "../components/team.vue";
import AboutCard from "../components/aboutCard.vue";

export default {
  components: { AboutCard, sixth, team, Carousel, Slide },
  data: function () {
    return {
      loading: false,
      DBitems: null,
    };
  },
  created() {
    /*this.loading = true;
    this.axios
      .get("https://etrolley.net/api/website/page/aboutus", {
        headers: {
          "Accept-Language": this.$i18n.locale
        }
      })
      .then(response => {
        this.DBitems = response.data.data.page;
      })
      .then(() => (this.loading = false));*/
  },
  methods: {},
};
</script>

<style scoped>
section h4 {
  font-family: "Kanit-Medium";
  font-size: 2em;
  color: var(--primary-color);
  line-height: 50px;
  text-align: center;
}
section p {
  /*  width: 80%;*/
  font-family: "Kanit-Light";
  font-size: 18px;
  line-height: 25px;
  max-width: 90%;
  color: var(--secondary-color);
  text-align: start;
  margin: 10px auto;
}
.container1 {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #707070;
  aspect-ratio: 2560/1153;
  background-blend-mode: multiply;
  clip-path: polygon(0% 0%, 100% 0, 100% 85%, 0 100%);
}

img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.heroCarousel {
  /*margin-top: 25px;
   height: 390px;
  width: 100%;
  max-width: 500px;*/
  /*aspect-ratio: 1200 / 300;*/

  border-radius: 15px;
  padding: 0;
}

.slider {
  /* background: url('../img/bg/slider-bg.png') center center no-repeat;*/
  font-family: "Kanit-Medium";
  background-color: transparent;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}

.slider .particels {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.animated-headlines .cd-headline span {
  font-size: 50px;
  font-weight: 700;
  /* text-transform: uppercase;*/
  color: #e0a80d;
}
.slider .left-content {
  /*margin-top: 50px;*/
  text-align: start;
}
.slider .left-content .hello {
  font-family: "Kanit-Medium";
  font-size: 2.5em;
  font-weight: 100;
  line-height: 50px;
  color: #fff;
  text-align: start;
}

.slider .left-content h2 {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  padding: 10px 0;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  display: inline-block;
  margin: 5px 0 20px 0;
}

.slider .left-content .paragraph {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 28px;
  padding-right: 20px;
}

.slider .left-content .btn {
  border: 2px solid var(--third-color);
  background-color: transparent;
  border-radius: 30px;
  padding: 10px 8px 10px 30px;
  font-size: 18px;
  font-family: "Kanit-Medium";
  color: #fff;
  font-weight: 700;
  margin-top: 30px;
  /*margin-bottom: 50px;*/
  text-decoration: none;
  z-index: 200;
  animation: animateBtn 0.7s alternate infinite;
}
.slider .left-content .btn:hover {
  transform: scale(1.05);
  color: var(--primary-color) !important;
}
[dir="rtl"] .slider .left-content .btn {
  padding: 10px 30px 10px 8px;
}
.slider .left-content .btn i {
  font-size: 25px;
  background-color: #fff;
  border-radius: 50px;
  padding: 4px 14px;
  margin: 0 0 0 10px;
  color: #000;
}
[dir="rtl"] .slider .left-content .btn i {
  margin: 0 10px 0 0;
}
.cd-headline {
  margin-top: 20px;
}

.cd-headline p {
  position: relative;
  font-family: "Kanit-Medium";
  max-width: 85%;
  font-size: 22px;
  line-height: 35px;
  overflow: hidden;
  color: #fff;
  font-weight: 100;
  text-align: start;
  margin: 0;
  /* background: linear-gradient(
    45deg,
    var(--primary-color),
    #026e8f,
    var(--primary-color)
  );
  background-repeat: round;
  background-size: 80%;
  animation: animate 10s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0); */ /* var(--primary-color);*/
}
[dir="rtl"] .cd-headline p {
  animation: animate1 10s linear infinite;
}
.timeline:before {
  content: "";
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 23px;
}
.timeline:before {
  border-left: 1px solid #000;
  opacity: 1;
}
@media (min-width: 1400px) {
  .cd-headline p {
    line-height: 35px !important;
  }
}
@media (min-width: 768px) {
  .timeline:before {
    left: 50%;
  }
}
#About {
  padding-top: 0px;
  min-height: 600px;
}
@media (max-width: 992px) {
  .container1 {
    padding-bottom: 0 !important;
    aspect-ratio: inherit;
    background-position: center;
  }
  .animated-headlines .cd-headline span {
    font-size: 25px;
  }
  .slider {
    margin-top: 55px;
  }
  .slider .left-content .btn {
    margin-top: 5px;
  }
  .cd-headline p {
    font-size: 15px;
    line-height: 18px;
  }
  .slider .left-content {
    margin-top: 30px;
  }
  #About {
    padding-top: 0px;
    min-height: 600px;
  }
  section h4 {
    font-family: "Kanit-Bold";
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  section p {
    text-align: center;
    font-size: 14px;
    line-height: 15px;
    max-width: 100%;
  }
  .timeline:before {
    right: 15%;
    left: auto;
    top: 0;
  }
}

@media (max-width: 575px) {
  .animated-headlines .cd-headline span {
    font-size: 16px;
  }
  .container1 {
    padding-bottom: 0 !important;
    aspect-ratio: inherit;
    background-position: center;
  }
  .slider .left-content .hello {
    font-size: 16px;
    line-height: 15px;
    color: #fff;
    font-family: "Kanit-Bold";
    font-size: 15px;
  }

  .slider .left-content h2 {
    font-size: 16px;
    font-weight: 600;
    padding: 10px 0;
    margin: 5px 0 20px 0;
  }

  .slider .left-content .paragraph {
    font-size: 14px;
    padding-right: 20px;
  }

  .slider .left-content .btn {
    padding: 5px 15px 5px 5px !important;
    font-size: 12px;
    font-weight: 700;
    margin-top: 5px;
  }
  .slider .left-content .btn i {
    font-size: 15px;
    padding: 0 4px;
  }
  .cd-headline p {
    font-size: 12px;
    line-height: 15px;
  }

  .slider .left-content {
    margin-top: 0px;
  }
  .cd-headline {
    margin-top: 5px;
  }
  section h4 {
    font-family: "Kanit-Bold";
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  section p {
    text-align: center;
    font-size: 14px;
    line-height: 15px;
    max-width: 100%;
  }
}
</style>
