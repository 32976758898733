<template>
  <a :href="store_link" target="_blank" rel="noopener noreferrer">
    <div class="card">
      <div class="d-flex flex-row justify-content-center">
        <div class="image">
          <!-- onclick="openTab('tab1')">-->
          <!--<img :src="store_image" />-->
          <img :src="store_image" />
        </div>
      </div>
      <div class="mask">
        <ul>
          <li v-for="item in store_discription" :key="item">
            <p>{{ item }}</p>
          </li>
        </ul>
      </div>
      <div class="text-card" style="text-align: center; margin-top: 10px">
        <h5>{{ store_name }}</h5>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    store_id: Number,
    store_name: String,
    store_link: String,
    store_image: String,
    store_discription: Array,
  },
  data: function () {
    return {};
  },
};
</script>

<style scoped>
.card {
  width: 100%;
  height: 100%;
  border: 0;
  background: transparent;
}
.text-card {
  position: absolute;
  top: 80%;
  border: 0;
  border-radius: 15px;
  width: 80%;
  margin: 100px 10% 0 10%;
  padding: 20px 20px 5px;
  background-color: #fff;
}
.card h5 {
  color: var(--primary-color);
  font-family: "Kanit-Medium";
  font-size: 16px;
}
.card p {
  color: var(--secondary-color);
  font-family: "Kanit-Medium";
  font-size: 13px;
}
.image {
  /* width: 100%;
  height: 130px;*/
  aspect-ratio: 800/400;
}
img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: cover;
}
.card:hover {
  color: var(--primary-color);
}
.card:hover > .mask {
  opacity: 1;
}
.mask {
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.527);
  position: absolute;
  transition: ease-in-out 0.3s;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 12px;
  align-items: center;
}
.mask p {
  color: #fff;
  font-size: 14px;
  margin: 0px;
  text-align: start;
}
.mask p i {
  color: var(--primary-color);
  font-size: 14px;
  margin: 10px;
}
@media (min-width: 1200px) {
  /*.card {
    width: 100%;
    max-width: 180px;
  }
  .image{
    height: 150px;
  }*/
}
/*@media (min-width: 1400px){
  .card {
    width: 200px;
  }
  .card h5 {
  font-size: 30px;
}
  .image{
    height: 200px;
  }
}

@media (min-width: 2000px){
  .card {
    width: 290px;
  }
  .card h5 {
  font-size: 30px;
}
  .image{
    height: 290px;
  }
}*/

@media (max-width: 575px) {
  .text-card {
    position: absolute;
    top: 60%;
    border: 0;
    border-radius: 15px;
    width: 90%;
    margin: 100px 1% 0 3%;
    padding: 10px 10px 0;
    background-color: #fff;
  }
  .card h5 {
    color: var(--primary-color);
    font-family: "Kanit-Medium";
    font-size: 12px;
  }
  .card p {
    color: var(--secondary-color);
    font-family: "Kanit-Medium";
    font-size: 10px;
    margin-bottom: 5px;
  }
  .image {
    /* width: 100%;
  height: 130px;*/
    aspect-ratio: 1200/400;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
  }
  .card:hover {
    color: var(--primary-color);
    transform: scale(1.02);
  }
}
</style>
