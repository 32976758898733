<template>
  <header
    v-if="!loading"
    id="header"
    :style="
      $route.name == 'home' || $route.name == 'about'
        ? { backgroundColor: 'transparent' }
        : { backgroundColor: '#153a57' }
    "
    class="d-flex align-items-center"
    :class="{ change_color: scrollPosition > 50 }"
  >
    <div
      class="container d-flex align-items-center justify-content-between"
      v-if="!this.$store.userTokenState"
    >
      <div class="logo mobile-inactive logo-1">
        <img src="../../public/assets/image/Can/minilogo-neu.png" alt />
      </div>
      <nav
        id="navbar"
        :class="'navbar' + (activeMobile ? ' navbar-mobile' : '')"
      >
        <i
          :class="
            'bi bi-list mobile-nav-toggle ' + (activeMobile ? ' bi-x' : '')
          "
          @click="activeMobile = !activeMobile"
        ></i>
        <ul>
          <li class="mobile-active">
            <div>
              <div
                class="logo d-flex flex-row justify-content-center align-items-center"
                style="height: 70px; margin-bottom: 0px"
              >
                <img src="../../public/assets/image/Can/minilogo-neu.png" alt />
              </div>
            </div>
          </li>
          <li>
            <router-link
              :to="{
                name: 'home',
              }"
              >{{ $t("home") }}</router-link
            >
          </li>

          <li @click="backHome('.Produkte')">
            <router-link
              :to="{
                name: 'home',
                hash: '#Produkte',
              }"
              >Produkte</router-link
            >
          </li>
          <li @click="backHome('.Kontakt')">
            <router-link
              :to="{
                name: 'home',
                hash: '#Kontakt',
              }"
              >Kontakt</router-link
            >
          </li>
          <li>
            <router-link
              :to="{
                name: 'about',
              }"
              >Über uns</router-link
            >
          </li>
        </ul>
        <!-- .navbar -->
      </nav>
      <div class="logo mobile-active">
        <img src="../../public/assets/image/Can/minilogo-neu.png" alt />
      </div>
    </div>
  </header>
</template>

<script>
import VueScrollTo from "vue-scrollto";

export default {
  // eslint-disable-next-line
  /*eslint-disable */
  name: "Header",
  components: { VueScrollTo },
  props: {
    // name: String,
    // image: String
    storeName: String,
  },
  data: function () {
    return {
      loading: false,
      loadingNote: false,
      activeMobile: false,
      word: "",
      scrollPosition: null,
      notifications: [],
    };
  },

  methods: {
    /*onChange: function (event) {
      this.$store.state.langchange = true;
      if (event.target.value == "ar") {
        document.documentElement.setAttribute("dir", "rtl");
      } else {
        document.documentElement.setAttribute("dir", "ltr");
      }
    },*/
    /* updateScroll() {
       this.scrollPosition = window.scrollY
    },*/
    onChange(lang) {
      this.$store.state.langchange = true;
      this.$store.state.langchange1 = true; //for footer
      this.$i18n.locale = lang;
      if (lang == "ar") {
        document.documentElement.setAttribute("dir", "rtl");
      } else {
        document.documentElement.setAttribute("dir", "ltr");
      }
    },

    
    backHome(link) {
      this.$router.push({
        name: "home",
      });
      setTimeout(
        () =>
          VueScrollTo.scrollTo(link, 200, {
            easing: "linear",
          }),
        200
      );
    },
    /* onClickMobile: function () {
      $("#navbar").addClass(" navbar-mobile");
      $(".mobile-nav-toggle").addClass("bi-list bi-x");
    },*/
  },
  /* mounted() {
    window.addEventListener('scroll', this.updateScroll);
}*/
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
[dir="rtl"] .bi-search {
  margin-right: 72%;
  margin-top: 6px;
  position: absolute;
}
[dir="ltr"] .bi-search {
  margin-left: 72%;
  margin-top: 6px;
  position: absolute;
}

.filterDropdown ul,
li {
  list-style: none;
}
.filterDropdown ul {
  display: block;
  position: absolute;
  left: 5px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 15px;
  min-height: 190px;
}

/*.navbar .dropdown ul li,*/

.filterDropdown ul li {
  min-width: 200px;
}

/*.navbar .dropdown ul a,*/

.filterDropdown ul a {
  padding: 10px 20px;
  color: #2a2c39;
  margin: 0 5px;
  font-size: 14px;
}

/*.navbar .dropdown ul a i,*/

.filterDropdown ul a i {
  font-size: 12px;
}

/*.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a,*/

.filterDropdown ul a:hover,
.filterDropdown ul .active:hover,
.filterDropdown ul li:hover > a {
  color: var(--primary-color);
}

/*.navbar .dropdown:hover>ul,*/

.filterDropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

/*.navbar .dropdown .dropdown ul,*/

.filterDropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

/*.navbar .dropdown .dropdown:hover>ul,*/

.filterDropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}
button {
  background-color: transparent;
  border: 0;
  /*border: 1px solid var(--primary-color);
  border-radius: 15px;
  padding: 10px;*/
}
.createAccountLI {
  padding: 0;
}
.createAccount {
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
  border-radius: 25px;
  padding: 5px 15px 7px 15px;
  cursor: pointer;
  color: #fff;
}

.createAccount:hover,
.createAccount:focus {
  border: 2px solid var(--third-color) !important;
  background-color: var(--third-color);
  transform: scale(1.02);
  color: var(--primary-color) !important;
}
.LangBtn {
  background-color: var(--third-color);
  border-radius: 25px;
  cursor: pointer;
  margin: auto 10px;
}
.LangBtn:hover {
  /*background-color: #fff;
  border: 2px solid var(--primary-color);*/
  transform: scale(1.02);
}
@media (min-width: 992px) {
  .logo-1 {
    min-width: 100px;
  }
}
@media (max-width: 1200px) and (min-width: 992px) {
  .navbar li {
    padding: 5px 7px 7px 7px;
  }
  .navbar a {
    padding: 5px 5px;
  }

  /* #header .logo img {
    max-height: 30px;
  }*/
}
</style>
