<template>
  <div >
    <div class="card">
      <div class="image">
        <img :src="store_image" />
      </div>
      <div class="mask">
        <!--<p>{{ store_tel }}</p>-->
        <div class="social">
          <a href=""><i class="bi bi-telephone"></i></a>
          <a href=""><i class="bi bi-envelope"></i></a>
        </div>
      </div>
      <div class="text-card" style="text-align: center">
        <h5>{{ store_name }}</h5>
        <p>{{ store_discription }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    store_id: Number,
    store_name: String,
    store_tel: String,
    store_image: String,
    store_discription: String,
  },
};
</script>

<style scoped>
.card {
  width: 90%;
  height: 100%;
  border: 0;
  background: transparent;
  margin: auto;
  margin-bottom: 40px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.text-card {
  border: 0;
  width: 100%;
  padding: 20px 20px 5px;
  background-color: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.card h5 {
  color: var(--primary-color);
  font-family: "Kanit-Medium";
  font-size: 16px;
}
.card p {
  color: var(--secondary-color);
  font-family: "Kanit-Medium";
  font-size: 13px;
}
.image {
  /* width: 100%;
  height: 130px;*/
  aspect-ratio: 400/400;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.card:hover > .mask {
  opacity: 1;
}
.mask {
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  position: absolute;
  bottom: 100px;
  right: 0;
  left: 0;
  border-radius: 12px;
  align-items: center;
  transition: ease-in-out 0.3s;
}
.mask a {
  transition: color 0.3s;
  margin: 0 3px;
  border-radius: 50px;
  width: 36px;
  height: 36px;
  background: rgba(236, 28, 36, 0.6);
  transition: ease-in-out 0.3s;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.mask a:hover {
  background: var(--primary-color);
  color: #fff !important;
}
@media (min-width: 1400px) {
  .mask {
    bottom: 128px;
  }
  .card h5 {
    font-size: 24px !important;
    line-height: inherit !important;
  }
  /*.card {
    width: 100%;
    max-width: 180px;
  }
  .image{
    height: 150px;
  }*/
}
/*@media (min-width: 1400px){
  .card {
    width: 200px;
  }
  .card h5 {
  font-size: 30px;
}
  .image{
    height: 200px;
  }
}

@media (min-width: 2000px){
  .card {
    width: 290px;
  }
  .card h5 {
  font-size: 30px;
}
  .image{
    height: 290px;
  }
}*/

@media (max-width: 575px) {
}
</style>
