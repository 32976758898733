import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/Home.vue";
import AboutView from "../views/About.vue";
import DATENSCHUTZERKLÄRUNGView from "../views/DATENSCHUTZERKLARUNG.vue";
import IMPRESSUMView from "../views/IMPRESSUM.vue";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/UBERUNS",
    name: "about",
    component: AboutView,
  },
  {
    path: "/DATENSCHUTZERKLARUNG",
    name: "DATENSCHUTZERKLARUNG",
    component: DATENSCHUTZERKLÄRUNGView,
  },
  {
    path: "/IMPRESSUM",
    name: "IMPRESSUM",
    component: IMPRESSUMView,
  },
];
//const storeName = 'can'

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      // This ensures that if hash is provided to router.push it works as expected.
      //  & since we have used "behavior: 'smooth'" the browser will slowly come to this hash position.
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }
    // always scroll to top
    else {
      return { x: 0, y: 0 };
    }
  },
  mode: "history",
  //base: "can.web.bestellgastro.de" + process.env.BASE_URL,
  base: process.env.BASE_URL,
  //base: "/",
  routes,
});

export default router;
