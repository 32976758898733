import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import en from './lang/en.json'
import ar from './lang/ar.json'


import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)


import VueSession from 'vue-session'
Vue.use(VueSession)


import VueCookies from 'vue-cookies'
Vue.use(VueCookies, { expires: '7d' })

import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";


import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import AOS from 'aos'
import 'aos/dist/aos.css'


import VuePageTransition from 'vue-page-transition'
Vue.use(VuePageTransition)

Vue.use(VueI18n)


Vue.config.productionTip = false


const i18n = new VueI18n({
    locale: 'en',
    messages: {
        'en': en,
        'ar': ar,
    }
})

import VueNumber from 'vue-number-animation'

Vue.use(VueNumber)

import "vue2-animate/dist/vue2-animate.min.css";

import VueParticles from 'vue-particles'
Vue.use(VueParticles)

new Vue({
    i18n,
    router,
    store,
    mounted() {
        AOS.init()
    },
    render: h => h(App),
}).$mount('#app')