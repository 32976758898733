import { render, staticRenderFns } from "./fifth.vue?vue&type=template&id=b99a641a&scoped=true"
import script from "./fifth.vue?vue&type=script&lang=js"
export * from "./fifth.vue?vue&type=script&lang=js"
import style0 from "./fifth.vue?vue&type=style&index=0&id=b99a641a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b99a641a",
  null
  
)

export default component.exports