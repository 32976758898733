<template>
  <div id="sliderTeam">
    <!-- ======= Second Section ======= -->
    <section  style="margin-top:50px">
      <div class="container">
        <div>
          <h4>Team</h4>
          <!--<h2>{{ $t('SixthDescription') }}</h2>-->
        </div>
        <div class="row mt-3">
          <slider-stores ></slider-stores>
        </div>
      </div>
    </section>
    <!-- End Second -->
  </div>
</template>
<script>
import sliderStores from "./sliderStores1.vue";

export default {
  components: {
    sliderStores
  },
  data() {
    return {
      DBitems: null
    };
  },
  created() {
   
  },
  methods: {
   
  }
};
</script>
<style scoped>
section {
  padding-top: 0px;
  padding-bottom: 80px;
  color: var(--primary-color);
  font-family: "Kanit-Medium";
  text-align: center;
}
section h2 {
  font-family: "Kanit-Bold";
  font-size: 2em;
  line-height: 40px;
  background: linear-gradient(
    45deg,
    var(--primary-color),
    #026e8f,
    var(--primary-color)
  );
  background-repeat: round;
  background-size: 80%;
  animation: animate 10s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}
section h4 {
    /* width: 80%; */
    font-family: "Kanit-Medium";
    font-size: 2em;

    line-height: 50px;
    text-align: center;
}
section .card h4 {
  height: 70px;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
}
section p {
  /*  width: 80%;*/
  font-family: "Kanit-Light";
  font-size: 11px;
  line-height: 15px;
  font-weight: 700;
}
.card {
  width: 100%;
  height: 90%;
  border: 0;
  border-radius: 25px;
  padding: 40px 0 20px 0;
  margin-top: 20px;
}
img {
  width: 50px;
  margin-bottom: 20px;
}
@media (max-width: 992px) {
}

@media (max-width: 1200px) {
}

@media (max-width: 630px) {
}

@media (max-width: 575px) {
  section {
    padding-top: 0px;
    margin-top: 0 !important;
    padding-bottom: 20px;
  }
  section h2 {
    font-family: "Kanit-Bold";
    font-size: 15px;
    line-height: 20px;
  }
  section h4 {
    font-size: 12px;
    line-height: 25px;
  }
}

@media (max-width: 400px) {
}
</style>
