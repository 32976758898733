<template>
  <div data-aos="fade-zoom-in" data-aos-duration="1200" >
    <div :class="isRight ? 'timeline-item right' : 'timeline-item'">
      <div class="timeline-item-inner">
        <div class="timeline-item-image">
          <div class="gallery-row">
            <div class="gallery-item gallery-item-size-1">
              <figure class="image">
                <img
                  :src="image"
                  width="911"
                  height="498"
                  intrinsicsize="911x498"
                  title=""
                  alt=""
                  loading="lazy"
                />
              </figure>
            </div>
          </div>
          <div class="timeline-item-date">{{ year }}</div>
        </div>
        <div class="timeline-item-header">
          <h4 class="timeline-item-header-headline">
            {{ name }}
          </h4>
        </div>
        <div class="timeline-item-text">
          <p>
            {{ description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: {
    id: Number,
    name: String,
    image: String,
    description: String,
    year: String,
    isRight: Boolean,
  },
  data: function () {
    return {};
  },
};
</script>
  
  <style scoped>
.timeline-item {
  width: 50%;
  position: relative;
  padding-top: 90px;
}
.timeline-item .timeline-item-inner {
  overflow: visible;
  border: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: right;
  background-color: transparent;
}
.timeline-item.active .timeline-item-image {
  color: #066;
}
.timeline-item .timeline-item-image {
  position: relative;
  padding: 0 112px 0 0;
  margin: 0;
  z-index: 1;
  color: #000;
}
.timeline-item .timeline-item-image:after {
  content: "";
  display: block;
  position: absolute;
  border-bottom: 1px solid currentColor;
  top: 50%;
  left: 50%;
  right: 0;
  z-index: -1;
}
.timeline-item .gallery-row {
  /*max-width: 377px;*/
  margin-left: auto;
}

.timeline-item .gallery-item,
.timeline-item .gallery-row {
  padding: 0;
  margin: 0;
}
.timeline-item .timeline-item-image picture {
  border: 1px solid currentColor;
  /*-webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;*/
  background-color: #fff;
}
.aos-animate .timeline-item .timeline-item-date {
  background: var(--third-color);
  color: #fff;

  font-family: cursive;
}

.timeline-item .timeline-item-date {
  font-size: 1rem;
  letter-spacing: 1px;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 3;
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  text-transform: uppercase;
  width: 96px;
  height: 60px;
  background: #fff;
  border: 1px solid #424242;
  transform: translate(50%, -50%);
  /*-webkit-transform: translate(50%, -50%);
  -moz-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  -o-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;*/
}

.timeline-item .timeline-item-header,
.timeline-item .timeline-item-text {
  margin: 30px 112px 20px auto;
}
.timeline-item .timeline-item-header h4 {
  font-family: "Kanit-Medium";
  font-size: 18px;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.timeline-item .timeline-item-text p {
  font-family: "Kanit-Light";
  font-size: 16px;
  line-height: 25px;
  color: var(--secondary-color);
}
.text-card {
  border: 0;
  width: 100%;
  padding: 20px 20px 5px;
  background-color: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.card h5 {
  color: var(--primary-color);
  font-family: "Kanit-Medium";
  font-size: 16px;
}
.card p {
  color: var(--secondary-color);
  font-family: "Kanit-Medium";
  font-size: 13px;
}

img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border: 1px solid #000;
}

.card:hover > .mask {
  opacity: 1;
}
.mask {
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  position: absolute;
  bottom: 100px;
  right: 0;
  left: 0;
  border-radius: 12px;
  align-items: center;
  /*transition: ease-in-out 0.3s;*/
}
.mask a {
  /*transition: color 0.3s;*/
  margin: 0 3px;
  border-radius: 50px;
  width: 36px;
  height: 36px;
  background: rgba(236, 28, 36, 0.6);
  /*transition: ease-in-out 0.3s;*/
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.mask a:hover {
  background: var(--primary-color);
  color: #fff !important;
}

@media (min-width: 992px) {
  .timeline-item.right {
    margin-left: 50%;
  }
  .timeline-item.right .timeline-item-image:after {
    left: 0;
    right: 50%;
  }
  .timeline-item.right .timeline-item-inner {
    margin-left: 0;
    margin-right: auto;
    text-align: left;
  }
  .timeline-item.right .timeline-item-image {
    padding: 0 0 0 112px;
  }
  .timeline-item.right .gallery-row {
    margin-right: auto;
    margin-left: 0;
  }
  .timeline-item.right .timeline-item-date {
    right: auto;
    left: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .timeline-item.right .timeline-item-header,
  .timeline-item.right .timeline-item-text {
    margin-left: 112px;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  /*.card {
      width: 100%;
      max-width: 180px;
    }
    .image{
      height: 150px;
    }*/
}
/*@media (min-width: 1400px){
    .card {
      width: 200px;
    }
    .card h5 {
    font-size: 30px;
  }
    .image{
      height: 200px;
    }
  }
  
  @media (min-width: 2000px){
    .card {
      width: 290px;
    }
    .card h5 {
    font-size: 30px;
  }
    .image{
      height: 290px;
    }
  }*/
@media (max-width: 992px) {
  .timeline-item {
    width: 85%;
    position: relative;
    margin-top: 90px;
  }
}
@media (max-width: 575px) {
  .text-card {
    position: absolute;
    top: 60%;
    border: 0;
    border-radius: 15px;
    width: 90%;
    margin: 100px 5% 0 5%;
    padding: 10px 10px 0;
    background-color: #fff;
  }
  .card h5 {
    color: var(--primary-color);
    font-family: "Kanit-Medium";
    font-size: 12px;
  }
  .card p {
    color: var(--secondary-color);
    font-family: "Kanit-Medium";
    font-size: 10px;
    margin-bottom: 5px;
  }
  .image {
    /* width: 100%;
    height: 130px;*/
    aspect-ratio: 1200/400;
  }
  img {
    width: 100%;
    height: 120px;
    border-radius: 5px;
    object-fit: cover;
  }
  .card:hover {
    color: var(--primary-color);
    transform: scale(1.02);
  }
  .timeline-item .timeline-item-date {
    font-size: 12px;
    width: 66px;
    height: 42px;
  }
  .timeline-item .timeline-item-image {
    padding: 0 60px 0 0;
  }
  .timeline-item .timeline-item-header,
  .timeline-item .timeline-item-text {
    margin: 15px 60px 20px auto;
  }
  .timeline-item .timeline-item-header h4 {
    font-size: 15px;
  }
  .timeline-item .timeline-item-text p {
    font-size: 14px;
    line-height: 18px;
  }
}
</style>
  