<template>
  <div id="Questions">
    <section v-if="!loading">
      <!--<div class="container" v-html="DBitems.title"></div>-->

      <div class="container">
        <h3>DATENSCHUTZERKLÄRUNG</h3>
        <div
          class="tatsu-text-inner tatsu-align-center clearfix"
          data-animation="fadeIn"
        >
          <p><strong>Datenschutzerklärung</strong></p>
          <p>
            Wir freuen uns über Ihr Interesse an unserer Webseite. Der Schutz
            Ihrer Privatsphäre ist für uns sehr wichtig. Nachstehend informieren
            wir Sie ausführlich über den Umgang mit Ihren Daten.
          </p>
          <p>
            Verantwortlich gem. Art.&nbsp;4 Abs.&nbsp;7
            EU-Datenschutz-Grundverordnung (DS-GVO) ist:
          </p>
          <p>
            Özgür Sarikaya &amp; Ekin Sarikaya<br />Gustav Hagemann Straße 46<br />38229
            Salzgitter<br />Telefon: +49 (0) 53 41 – 790 686 0<br />Telefax: +49
            (0) 53 41 – 790 686 3<br />E-Mail:
            <a href="mailto:info@can-grosshandel.de">info@can-grosshandel.de</a>
          </p>
          <ol>
            <li>
              <strong>
                Information über die Erhebung personenbezogener Daten und
                Impressumkennzeichnung</strong
              >
            </li>
          </ol>
          <p>
            (1) Im Folgenden informieren wir über die Erhebung personenbezogener
            Daten bei Nutzung dieser Website. Personenbezogene Daten sind alle
            Daten, die auf Sie persönlich beziehbar sind, also z. B. Name,
            Adresse, E-Mail-Adressen, Nutzerverhalten.
          </p>
          <p>
            (2) Diensteanbieter gem. § 13 Telemediengesetz (TMG) und
            verantwortliche Stelle gem. § 3 Abs. 7 Bundesdatenschutzgesetz
            (BDSG) ist Firma CAN Großhandel GmbH &amp; Co. KG, vgl. dazu unsere
            Impressuminformationen –
            <a href="https://www.can-grosshandel.de/impressum/"
              >https://www.can-grosshandel.de/impressum/</a
            >
          </p>
          <ol start="2">
            <li><strong> Rechte auf Auskunft und Widerruf</strong></li>
          </ol>
          <p>
            (1) Sie haben das Recht, von uns jederzeit Auskunft zu verlangen
            über die zu Ihnen bei uns gespeicherten Daten, sowie zu deren
            Herkunft, Empfängern oder Kategorien von Empfängern, an die diese
            Daten weitergegeben werden und den Zweck der Speicherung.
          </p>
          <p>
            (2) Wenn Sie eine Einwilligung zur Nutzung von Daten erteilt haben,
            können Sie diese jederzeit widerrufen.
          </p>
          <p>
            (3) Alle Informationswünsche, Auskunftsanfragen oder Widersprüche
            zur Datenverarbeitung richten Sie bitte per E-Mail an
            <a href="mailto:info@can-grosshandel.de">info@can-grosshandel.de</a>
            oder an die unter Impressuminformationen –
            <a href="https://www.can-grosshandel.de/impressum/"
              >https://www.can-grosshandel.de/impressum/</a
            >
            &nbsp;– genannte Adresse.
          </p>
          <ol start="3">
            <li><strong> Datensicherheit</strong></li>
          </ol>
          <p>
            Wir unterhalten aktuelle technische Maßnahmen zur Gewährleistung der
            Datensicherheit, insbesondere zum Schutz Ihrer personenbezogenen
            Daten vor Gefahren bei Datenübertragungen sowie vor
            Kenntniserlangung durch Dritte. Diese werden dem aktuellen Stand der
            Technik entsprechend jeweils angepasst.
          </p>
          <ol start="4">
            <li>
              <strong>
                Erhebung personenbezogener Daten bei informatorischer Nutzung,
                Cookies</strong
              >
            </li>
          </ol>
          <p>
            (1) Bei der bloß informatorischen Nutzung der Website, also wenn Sie
            sich nicht registrieren oder uns anderweitig Informationen
            übermitteln, erheben wir nur die personenbezogenen Daten, die Ihr
            Browser an unseren Server übermittelt. Wenn Sie unsere Website
            betrachten möchten, erheben wir die folgenden Daten, die für uns
            technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und
            die Stabilität und Sicherheit zu gewährleisten (Rechtsgrundlage ist
            Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit. f DS-GVO):
          </p>
          <ul>
            <li>IP-Adresse</li>
            <li>Datum und Uhrzeit der Anfrage</li>
            <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
            <li>Inhalt der Anforderung (konkrete Seite)</li>
            <li>Zugriffsstatus/HTTP-Statuscode</li>
            <li>jeweils übertragene Datenmenge</li>
            <li>Website, von der die Anforderung kommt</li>
            <li>Browser</li>
            <li>Betriebssystem und dessen Oberfläche</li>
            <li>Sprache und Version der Browsersoftware</li>
          </ul>
          <p>
            (2) Weiterhin werden bei Nutzung der Website
            <strong>Cookies</strong> auf Ihrem Rechner gespeichert. Bei Cookies
            handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem
            von Ihnen verwendeten Browser zugeordnet gespeichert werden und
            durch welche der Stelle, die den Cookie setzt (in diesem Fall also
            uns), bestimmte Informationen zufließen. Cookies können keine
            Programme ausführen oder Viren auf Ihren Computer übertragen. Sie
            dienen dazu, das Internetangebot insgesamt nutzerfreundlicher und
            effektiver zu machen.
          </p>
          <ol>
            <li>a) Diese Website nutzt Cookies in folgendem Umfang:</li>
          </ol>
          <p>– Transiente Cookies (temporärer Einsatz)</p>
          <p>– Persistente Cookies (zeitlich beschränkter Einsatz)</p>
          <p>– Third-Party Cookies (von DrittImpressumn)</p>
          <p>– Flash-Cookies (dauerhafter Einsatz).</p>
          <ol>
            <li>
              b) Transiente Cookies werden automatisiert gelöscht, wenn Sie den
              Browser schließen. Dazu zählen insbesondere die Session-Cookies.
              Diese speichern eine sogenannte Session-ID, mit welcher sich
              verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung
              zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden,
              wenn Sie auf die Website zurückkehren. Die Session-Cookies werden
              gelöscht, wenn Sie sich ausloggen oder Sie den Browser schließen.
            </li>
            <li>
              c) Persistente Cookies werden automatisiert nach einer
              vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden
              kann. Sie können die Cookies in den Sicherheitseinstellungen Ihres
              Browsers jederzeit löschen.
            </li>
            <li>
              d) Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen
              konfigurieren und z. B. die Annahme von Third-Party-Cookies oder
              allen Cookies ablehnen. Wir weisen Sie jedoch darauf hin, dass Sie
              dann eventuell nicht alle Funktionen dieser Website nutzen können.
            </li>
            <li>
              e) Die genutzten Flash-Cookies werden nicht durch Ihren Browser
              erfasst, sondern durch Ihr Flash-Plug-in. Diese speichern die
              notwendigen Daten unabhängig von Ihrem verwendetem Browser und
              haben kein automatisches Ablaufdatum. Wenn Sie keine Verarbeitung
              der Flash-Cookies wünschen, müssen Sie ein entsprechendes Add-On
              installieren, z. B. „Better Privacy“ für Mozilla Firefox (<a
                href="https://addons.mozilla.org/de/firefox/addon/betterprivacy/"
                >https://addons.mozilla.org/de/firefox/addon/betterprivacy/</a
              >
              ) oder Adobe-Flash- Killer-Cookie für Google Chrome.
            </li>
          </ol>
          <p>
            (3) Diese gespeicherten Informationen werden getrennt von eventuell
            weiter bei uns angegeben Daten gespeichert. Insbesondere werden die
            Daten der Cookies nicht mit Ihren weiteren Daten verknüpft.
          </p>
          <ol start="5">
            <li>
              <strong> Erhebung weiterer personenbezogener Daten </strong>
            </li>
          </ol>
          <p>
            (1) Weitere personenbezogene Daten werden durch uns nur dann
            erfasst, wenn Sie uns diese von sich aus mitteilen, beispielsweise
            wenn Sie mit uns per E-Mail oder über das Kontaktformular in Kontakt
            treten. Die uns auf diese Weise übermittelten personenbezogenen
            Daten werden wir selbstverständlich ausschließlich für den Zweck
            verwenden, zu dem Sie uns diese bei der Kontaktierung zur Verfügung
            stellen.
          </p>
          <p>
            (2) Eine Mitteilung dieser Angaben erfolgt ausdrücklich auf
            freiwilliger Basis und mit Ihrer Einwilligung. Soweit es sich
            hierbei um Angaben zu Kommunikationskanälen (beispielsweise
            E-Mail-Adresse, Telefonnummer) handelt, willigen Sie außerdem ein,
            dass wir Sie ggf. auch über diesen Kommunikationskanal kontaktieren,
            um Ihr Anliegen zu beantworten.
          </p>
          <p>
            (3) Ihre Einwilligungserklärungen können Sie selbstverständlich
            jederzeit für die Zukunft widerrufen. Den Widerruf richten Sie bitte
            per E-Mail an
            <u
              ><a href="mailto:info@can-grosshandel.de"
                >info@can-grosshandel.de</a
              ></u
            >
            oder an die unter Impressuminformationen –&nbsp;<a
              href="https://www.can-grosshandel.de/impressum/"
              >https://www.can-grosshandel.de/impressum/</a
            >
            &nbsp;– genannte Adresse.
          </p>
          <ol start="7">
            <li><strong> Einbindung der Dienste Dritter</strong></li>
          </ol>
          <p>
            (1) Wir haben YouTube-Videos in unser Online-Angebot eingebunden,
            die auf http://www.YouTube.com gespeichert sind und von unserer
            Website aus direkt abspielbar sind. Diese sind alle im „erweiterten
            Datenschutz-Modus“ eingebunden, d. h. dass keine Daten über Sie als
            Nutzer an YouTube übertragen werden, wenn Sie die Videos nicht
            abspielen. Erst wenn Sie die Videos abspielen, werden die in
            Abs.&nbsp;3 genannten Daten übertragen. Auf diese Datenübertragung
            haben wir keinen Einfluss.
          </p>
          <p>
            (2) Wir haben auf dieser Website weiterhin Google Maps des
            Drittanbieters Google Inc. eingebunden.
          </p>
          <p>
            (3) Durch den Besuch auf der Website erhält der Drittanbieter die
            Information, dass Sie die entsprechende Unterseite unserer Website
            aufgerufen haben. Zudem werden die unter § 4 dieser Erklärung
            genannten Daten übermittelt. Dies erfolgt unabhängig davon, ob
            dieser Drittanbieter ein Nutzerkonto bereitstellt, über das Sie
            eingeloggt sind, oder ob kein Nutzerkonto besteht. Wenn Sie bei dem
            Plug-in-Anbieter eingeloggt sind, werden diese Daten direkt Ihrem
            Konto zugeordnet. Wenn Sie die Zuordnung mit Ihrem Profil bei dem
            Plug-in-Anbieter nicht wünschen, müssen Sie sich vor Aktivierung des
            Buttons ausloggen.
          </p>
          <p>
            (4) Der Plug-in-Anbieter speichert diese Daten als Nutzungsprofile
            und nutzt diese für Zwecke der Werbung, Marktforschung und/oder
            bedarfsgerechten Gestaltung seiner Website. Eine solche Auswertung
            erfolgt insbesondere (auch für nicht eingeloggte Nutzer) zur
            Erbringung von bedarfsgerechter Werbung und um andere Nutzer des
            sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu
            informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung
            dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an den
            jeweiligen Plug-in-Anbieter richten müssen.
          </p>
          <p>
            (5) Weitere Informationen zu Zweck und Umfang der Datenerhebung und
            ihrer Verarbeitung durch den Plug-in-Anbieter erhalten Sie in den im
            Folgenden mitgeteilten Datenschutzerklärungen dieser Anbieter. Dort
            erhalten Sie auch weitere Informationen zu Ihren diesbezüglichen
            Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer
            Privatsphäre.
          </p>
          <p>
            (6) Adressen der jeweiligen Anbieter und URL mit deren
            Datenschutzhinweisen:
          </p>
          <ul>
            <li>
              YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA;
              <a href="https://www.google.de/intl/de/policies/privacy/"
                >https://www.google.de/intl/de/policies/privacy/</a
              >
            </li>
            <li>
              Google Inc., 1600 Amphitheater Parkway, Mountainview, California
              94043, USA;
              <a href="https://www.google.com/policies/privacy/partners/?hl=de"
                >https://www.google.com/policies/privacy/partners/?hl=de</a
              >.
            </li>
          </ul>
          <ol start="9">
            <li><strong> Google Analytics</strong></li>
          </ol>
          <ul>
            <li>
              (1) Diese Website benutzt Google Analytics, einen Webanalysedienst
              der Google Inc. (“Google“). Google Analytics verwendet sog.
              „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden
              und die eine Analyse der Benutzung einer Internetseite durch Sie
              ermöglichen. Die durch den Cookie erzeugten Informationen über
              Ihre Benutzung einer Internetseite werden in der Regel an einen
              Server von Google in den USA übertragen und dort gespeichert.
            </li>
            <li>
              (2) Wir haben für unsere Internetseiten die sog. IP-Anonymisierung
              aktiviert, so dass Ihre IP-Adresse von Google innerhalb von
              Mitgliedstaaten der Europäischen Union oder in anderen
              Vertragsstaaten des Abkommens über den Europäischen
              Wirtschaftsraum zuvor gekürzt wird, um so eine direkte
              Personenbeziehbarkeit auszuschließen. Nur in Ausnahmefällen wird
              die volle IP-Adresse an einen Server von Google in den USA
              übertragen und dort gekürzt.
            </li>
            <li>
              (3) In unserem Auftrag wird Google diese Informationen benutzen,
              um Ihre Nutzung unserer Internetseiten auszuwerten, um Reports
              über die Websiteaktivitäten zusammenzustellen und um für uns
              weitere mit der Internetseitennutzung und der Internetnutzung
              verbundene Dienstleistungen zu erbringen.
            </li>
            <li>
              (4) Die im Rahmen von Google Analytics von Ihrem Browser
              übermittelte IP-Adresse wird nicht mit anderen Daten von Google
              zusammengeführt.
            </li>
            <li>
              (5) Sie können die Speicherung der Cookies durch eine
              entsprechende Einstellung Ihrer Browser-Software verhindern; wir
              weisen Sie jedoch darauf hin, dass Sie in diesem Fall
              gegebenenfalls nicht sämtliche Funktionen einer Internetseite
              vollumfänglich werden nutzen können. Sie können darüber hinaus die
              Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung
              einer Internetseite Website bezogenen Daten (inkl. Ihrer
              IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
              Google verhindern, indem sie das unter dem folgenden Link
              verfügbare Deaktivierungs-Add-on herunterladen und installieren.
              Der aktuelle Link ist:
              <a href="http://tools.google.com/dlpage/gaoptout?hl=de"
                >http://tools.google.com/dlpage/gaoptout?hl=de</a
              >).
            </li>
            <li>
              (6) Die Nutzung von Google Analytics erfolgt in Übereinstimmung
              mit den Voraussetzungen, auf die sich die deutschen
              Datenschutzbehörden mit Google verständigten. Informationen des
              DrittImpressums: Google Dublin, Google Ireland Ltd., Gordon House,
              Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436 1001.
              Nutzerbedingungen:
              <a href="http://www.google.com/analytics/terms/de.html"
                >http://www.google.com/analytics/terms/de.html</a
              >, Übersicht zum Datenschutz:
              <a
                href="http://www.google.com/intl/de/analytics/learn/privacy.html"
                >http://www.google.com/intl/de/analytics/learn/privacy.html</a
              >, sowie die Datenschutzerklärung:
              <a href="http://www.google.de/intl/de/policies/privacy"
                >http://www.google.de/intl/de/policies/privacy</a
              >.
            </li>
          </ul>
          <ol start="10">
            <li><strong> Änderungen der Datenschutzerklärung</strong></li>
          </ol>
          <p>
            Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit
            unter Beachtung der geltenden Datenschutzvorschriften zu ändern.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  components: {},
  data: function () {
    return {
      loading: false,
      DBitems: null,
    };
  },
  created() {
    /* this.loading = true;
    this.axios
      .get("https://etrolley.net/api/website/page/privacypolice", {
        headers: {
          "Accept-Language": this.$i18n.locale,
        },
      })
      .then((response) => {
        this.DBitems = response.data.data.page;
      })
      .then(() => (this.loading = false));*/
  },
  methods: {},
};
</script>

<style scoped>
#Questions {
  padding-top: 150px;
  min-height: 600px;
}
[dir="ltr"] .card {
  border-left: 3px solid var(--primary-color);
  border-radius: 0px;
  padding: 10px;
  margin-bottom: 20px;
  height: 90%;
}
[dir="rtl"] .card {
  border-right: 3px solid var(--primary-color);
  border-radius: 0px;
  padding: 10px;
  margin-bottom: 20px;
  height: 90%;
}
i {
  color: darkgreen;
}
h4 {
  color: var(--primary-color);
}
h6 {
  color: #6f6e6e;
  width: 90%;
  margin: 20px 5% 40px;
  height: 75px;
  overflow: auto;
}
section h3 {
  color: var(--primary-color);
  margin-top: 20px;
  margin-bottom: 20px;
}
/*.faq-wrapper{
    max-width: 825px;
}*/
@media (max-width: 992px) {
  #Questions {
    padding-top: 85px;
    min-height: 600px;
  }
}
</style>

