<template>
  <div>
    <!--<div v-if="this.$store.state.langchange">{{ updatedata() }}</div>-->
    <!-- ======= Second Section ======= -->
    <section style="margin-top:0px">
      <div class="container">
        <div style="margin-top:50px ;">
          <h4>{{ $t('EighthTitle') }}</h4>
          <!--<h2>{{ $t('EighthDescription') }}</h2>
          <p>{{ $t('EighthSubDescription') }}</p>
          <p>{{ $t('EighthSubDescription2') }}</p>-->
        </div>
        <div class=" mt-5">
          <router-link class="btn" dir="auto" 
                          :to="{
                              name: 'createAccount',
                            }">
                            {{ $t("CreateStore") }}
                            <i
                              :class="$i18n.locale == 'ar' ?'bi bi-arrow-left' : 'bi bi-arrow-right'"
                            ></i>
                          </router-link>
        </div>
      </div>
    </section>
    <!-- End Second -->
  </div>
</template>
<script>
import priceCard from "./priceCard.vue";
import priceCard2 from "./priceCardStyle2.vue";

export default {
  components: {
    priceCard,
    priceCard2
  },
  data() {
    return {
      features: Array(
        "3 جيجابايت مساتحة تخزبن",
        "دعم فني",
        "ربط النطاق الخاص بك",
        "لغة أساسية + لغة إضافية"
      )
    };
  },
  created() {
    /*this.loading = true;
    //////////////////////home//////////////////////
    this.axios
      .get(this.$store.state.base_api_url + this.$store.storeName + "/home", {
        headers: {
          "Accept-Language": this.$i18n.locale,
        },
      })
      .then((response) => (this.DBitems = response.data.data.market))
      .then(() => (this.loading = false));*/
  },
  methods: {
    /*updatedata() {
      console.log("hooooooooooooooo");
      this.loading = true;
      //////////////////////home//////////////////////
      this.axios
        .get(this.$store.state.base_api_url + this.$store.storeName + "/home", {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        })
        .then((response) => (this.DBitems = response.data.data.market))
        .then(() => (this.loading = false));

      this.$store.state.langchange = false;
    },*/
  }
};
</script>
<style scoped>
section {
  padding-top: 0px;
  padding-bottom: 80px;
  color: var(--primary-color);
  font-family: "Kanit-Medium";
  text-align: center;

 background-color: var(--primary-color);
 background-image: url('http://etrolley.net/websiteImages/background/2.jpg');
 background-repeat :  no-repeat;
 background-attachment : fixed ;
 background-position-y : 100px;
 background-size: 100%;
 /*background-blend-mode: color-dodge; overlay*/
 background-blend-mode:  multiply;

}
section h2 {
  font-family: "Kanit-Bold";
  font-size: 2em;
  line-height: 40px;
  background: linear-gradient(
    45deg,
    var(--third-color),
    #fff,
    var(--third-color)
  );
  background-repeat: round;
  background-size: 80%;
  animation: animate 10s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}
section h4 {
  /*  width: 80%;*/
  font-family: "Kanit-Medium";
  font-size: 1.3em;
  line-height: 40px;
   color: #fff;
   text-align: start;
}
section .card h4 {
  height: 70px;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
 
}
section p {
  /*  width: 80%;*/
  font-family: "Kanit-Medium";
  font-size: 16px;
  line-height: 15px;
  color: #fff;
  margin-top: 20px;
}
.card {
  width: 100%;
  height: 90%;
  border: 0;
  border-radius: 25px;
  padding: 40px 0 20px 0;
  margin-top: 20px;
}
.btn {
  /*border: 2px solid var(--primary-color);*/
  background-color: var(--third-color);
  border-radius: 30px;
  padding: 10px 8px 10px 30px;
  font-size: 18px;
  font-family: "Kanit-Medium";
  color: var(--primary-color);
  font-weight: 700;
  text-decoration: none;
  z-index: 200;
  animation: animateBtn1 1s alternate infinite;
}
.btn:hover {
  transform: scale(1.05);
  background-color: var(--third-color);
}
[dir="rtl"] .btn {
  padding: 10px 30px 10px 8px;
}
.btn i {
  font-size: 25px;
  background-color: #ffe9c8;
  border-radius: 50px;
  padding: 4px 14px;
  margin: 0 0 0 10px;
}
[dir="rtl"] .btn i {
  margin: 0 10px 0 0;
}
img {
  width: 50px;
  margin-bottom: 20px;
}
@media (max-width: 992px) {
   section {
    min-height: 20px;
    padding: 15px;
    background-position-y: 100px;
    background-size: 250%;
    background-blend-mode:  color;

  }
}

@media (max-width: 1200px) {
}

@media (max-width: 630px) {
}

@media (max-width: 575px) {
  section {
    min-height: 20px;
    padding: 15px;
    background-position-y: 100px;
    background-size: 250%;
    background-blend-mode:  color;

  }
  section h2{
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 10px !important;
  }
  section h4 {
    font-size: 12px;
    line-height: 30px;
  }
  section p {
    font-size: 12px;
    line-height: 2px;
  }
  section .container div{
    margin-top: 0 !important;
  }
   .btn {
    padding: 10px 8px 10px 30px;
    font-size: 12px;
    font-weight: 700;
    margin-top: 10px;
  }
  .btn i {
    font-size: 15px;
    padding: 4px 10px;
  }
  .cd-headline b {
    line-height: 50px;
  }
}

@media (max-width: 400px) {
}
</style>
