var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('header',{staticClass:"d-flex align-items-center",class:{ change_color: _vm.scrollPosition > 50 },style:(_vm.$route.name == 'home' || _vm.$route.name == 'about'
      ? { backgroundColor: 'transparent' }
      : { backgroundColor: '#153a57' }),attrs:{"id":"header"}},[(!this.$store.userTokenState)?_c('div',{staticClass:"container d-flex align-items-center justify-content-between"},[_vm._m(0),_c('nav',{class:'navbar' + (_vm.activeMobile ? ' navbar-mobile' : ''),attrs:{"id":"navbar"}},[_c('i',{class:'bi bi-list mobile-nav-toggle ' + (_vm.activeMobile ? ' bi-x' : ''),on:{"click":function($event){_vm.activeMobile = !_vm.activeMobile}}}),_c('ul',[_vm._m(1),_c('li',[_c('router-link',{attrs:{"to":{
              name: 'home',
            }}},[_vm._v(_vm._s(_vm.$t("home")))])],1),_c('li',{on:{"click":function($event){return _vm.backHome('.Produkte')}}},[_c('router-link',{attrs:{"to":{
              name: 'home',
              hash: '#Produkte',
            }}},[_vm._v("Produkte")])],1),_c('li',{on:{"click":function($event){return _vm.backHome('.Kontakt')}}},[_c('router-link',{attrs:{"to":{
              name: 'home',
              hash: '#Kontakt',
            }}},[_vm._v("Kontakt")])],1),_c('li',[_c('router-link',{attrs:{"to":{
              name: 'about',
            }}},[_vm._v("Über uns")])],1)])]),_vm._m(2)]):_vm._e()]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo mobile-inactive logo-1"},[_c('img',{attrs:{"src":require("../../public/assets/image/Can/minilogo-neu.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"mobile-active"},[_c('div',[_c('div',{staticClass:"logo d-flex flex-row justify-content-center align-items-center",staticStyle:{"height":"70px","margin-bottom":"0px"}},[_c('img',{attrs:{"src":require("../../public/assets/image/Can/minilogo-neu.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo mobile-active"},[_c('img',{attrs:{"src":require("../../public/assets/image/Can/minilogo-neu.png"),"alt":""}})])
}]

export { render, staticRenderFns }