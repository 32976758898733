<template>
  <hooper
    id="storeSlider"
    style="
      height: 100%;
      margin-top: 50px;
      padding-top: 0px;
      padding-bottom: 0px;
    "
    :rtl="$i18n.locale == 'ar' ? true : false"
    :settings="hooperSettings"
  >
    <slide style="padding: 10px">
      <store-card
        :store_id="1"
        store_image="/assets/image/Can/Produkte/IMG_2318.jpg"
        store_name="Non-Food"
        :store_discription="items1"
      ></store-card>
    </slide>
    <slide style="padding: 10px">
      <store-card
        :store_id="2"
        store_image="/assets/image/Can/Produkte/shutterstock_104356544.jpg"
        store_name="Frischprodukte"
        :store_discription="items2"
      ></store-card>
    </slide>
    <slide style="padding: 10px">
      <store-card
        :store_id="3"
        store_image="/assets/image/Can/Produkte/shutterstock_303166640.jpg"
        store_name="Helal"
        :store_discription="items3"
      ></store-card>
    </slide>
    <slide style="padding: 10px">
      <store-card
        :store_id="4"
        store_image="/assets/image/Can/Produkte/shutterstock_383178109.jpg"
        store_name="Tiefkühlprodukte"
        :store_discription="items4"
      ></store-card>
    </slide>
    <slide style="padding: 10px">
      <store-card
        :store_id="5"
        store_image="/assets/image/Can/Produkte/shutterstock_574269415.jpg"
        store_name="Trockenware"
        :store_discription="items5"
      ></store-card>
    </slide>
    <slide style="padding: 10px">
      <store-card
        :store_id="6"
        store_image="/assets/image/Can/Produkte/shutterstock_670327150.jpg"
        store_name="Kühlprodukte"
        :store_discription="items6"
      ></store-card>
    </slide>

    <!--<slide style="padding:10px">
      <store-card
        :store_id="5"
        store_name="دريم لتطوير الويب"
        store_link="https://etrolley.net/dreamwd"
        store_image="https://etrolley.net/images/slider/1385880959_1672226736.jpg"
        store_discription="هذا النص هو مثال لنص"
      ></store-card>
    </slide>
    <slide style="padding:10px">
      <store-card
        :store_id="4"
        store_name="ماركات"
        store_link="https://etrolley.net/sara_market9"
        store_image="https://etrolley.net/media/slider/WhatsApp Image 2023-01-02 at 2.11.20 PM (2)_1672661557.jpeg"
        store_discription="هذا النص هو مثال لنص"
      ></store-card>
    </slide>-->
    <hooper-navigation slot="hooper-addons"></hooper-navigation>
    <hooper-pagination slot="hooper-addons"></hooper-pagination>
    <!--<hooper-pagination slot="hooper-addons"></hooper-pagination>
    <hooper-progress slot="hooper-addons"></hooper-progress>-->
  </hooper>
</template>

<script>
/* :store_id="item.product_id"
        :store_name="item.product_name"
        :store_link="item.product_name"
        :store_image="item.image"
        :store_discription="item.discount"*/
import storeCard from "./storeCard.vue";
import {
  Hooper,
  Slide,
  Progress as HooperProgress,
  Pagination as HooperPagination,
  Navigation as HooperNavigation,
} from "hooper";
import "hooper/dist/hooper.css";

export default {
  name: "App",
  props: { items: Array },
  components: {
    Hooper,
    Slide,
    HooperProgress,
    HooperPagination,
    HooperNavigation,
    storeCard,
  },
  data() {
    return {
      items1: [
        "Papier / Karton / Pizzakartons",
        "Aluminium / Plastik",
        "Dekorationsartikel",
        "Reinigungsmittel",
      ],
      items2: ["Frischgemüse", "Obst"],
      items3: ["Fleisch", "Wurst", "Milchprodukte"],
      items4: [
        "Fleisch  / Fleischwaren",
        "Fisch / Meeresfrüchte",
        "Geflügel",
        "Gemüse / Kartoffelprodukte",
        "Brot / Teigwaren",
        "Snacks",
      ],
      items5: [
        "Mehl / Teigwaren",
        "Kräuter / Gewürze",
        "Konserven / Soßen",
        "Reis / Nudeln",
        "Öle / Fette",
      ],
      items6: ["Fleisch / Fleischwaren", "Milchprodukte"],
      hooperSettings: {
        itemsToShow: 1,
        initialSlide: 0,
        itemsToSlide: 1,
        infiniteScroll: true,
        autoPlay: true,
        playSpeed: 3000,
        trimWhiteSpace: true,
        playSpeed: 2000,
        transition: 300,
        centerMode: true,
        pagination: "fraction",

        wheelControl: false,

        breakpoints: {
          475: {
            itemsToShow: 2.2,
            itemsToSlide: 1,
          },
          /* 768: {
            itemsToShow: 3
          },
          992: {
            itemsToShow: 4
          },
          1200: {
            itemsToShow: 5,
          }*/
        },
      },
    };
  },
};
</script>
<style scoped>

h1,
h4 {
  cursor: default;
}

a {
  text-decoration: none;
}

h1 {
  margin: 0;
  font-family: "Dosis", "Source Sans Pro", "Helvetica Neue", sans-serif;
  font-weight: 300;
  font-size: 4em;
  color: #2c3e50;
}

h4,
a {
  font-family: "Source Sans Pro", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #7f8c8d;
}

img.google {
  position: absolute;
  bottom: 0;
  left: 5px;
}

.container {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 240px;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(255, 255, 255, 1) 40%
  );
}

.row {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.location {
  font-family: "Dosis", "Source Sans Pro", "Helvetica Neue", sans-serif;
  color: #5dc596;
}

.social {
  justify-content: flex-end;
  flex-direction: row;
}

.social iframe {
  margin: 0 4px;
}

.button {
  display: inline-block;
  width: 180px;
  padding: 12px 14px;
  background-color: #4fc08d;
  font-weight: 700;
  color: #fff;
  border-bottom: 2px solid #3aa373;
  border-radius: 4px;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.button:hover {
  background-color: #5dc596;
  -webkit-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  transform: translate(0, -2px);
}
</style>
