<template>
  <div>
    <footer id="footer">
      <!--<div class="footer-image mobile-inactive"></div>-->
      <div class="container mt-3">
        <div class="row footer-width" style="width: 100%">
          <!--<div class="col-lg-3 mb-3">
            <div class="logo d-flex flex-row">
              <a href="index.html">
                <img
                  src="../../public/assets/image/Can/minilogo-neu.png"
                  alt
                  class="img-fluid"
                  style="width:100%"
                />
              </a>
            </div>
            <p>Ihr Qualitätslieferant für die Bereiche Großküchen und Gastronomie. Durch unseren Standpunkt in Salzgitter können wir unsere Kunden in einem Umkreis von 200 Kilometern schnell erreichen.</p>
            
           
          </div>-->
         
          <div class="col-lg-4 col-12 mb-1" style="margin-top: 30px">
            <!-- <div class="d-flex flex-row justify-content-around"> -->
            <div>
              <div>
                <h2 class="mb-4">CAN Großhandel GmbH &
Co. KG</h2>
                <!--<router-link
                  :to="{
                    name: 'home',
                    params: { storeName: this.$store.storeName },
                  }"
                >
                  <p>{{ $t("company") }}</p>
                </router-link>-->
                <router-link :to="{
                name: 'home',
              }">
                  <p>{{ $t("home") }}</p>
                </router-link>
                <router-link
                  :to="{
                    name: 'home',
                  }"
                >
                  <p>Produkte</p>
                </router-link>
                <router-link :to="{
                name: 'about',
              }">
                  <p>Über uns</p>
                </router-link>
                
                <router-link
                  :to="{
                name: 'DATENSCHUTZERKLARUNG',
              }"
                >
                  <p>Datenschutzerklärung</p>
                </router-link>
                <router-link
                  :to="{
                    name: 'IMPRESSUM',
                  }"
                >
                  <p>Impressum</p>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-6 mb-1" style="margin-top: 30px">
            <!-- <div class="d-flex flex-row justify-content-around"> -->
            <div>
              <div class="contact-info">
                <h2 class="mb-4">Kontakt</h2>
                <h1 class="phoneNumber">
                  <p dir="ltr"><i data-v-504c80e1="" class="bi bi-phone"></i>(+49) 53 41 - 79 06 86 0</p>
                  <!--<p>{{ DBitems.social.phone }}</p>-->
                  <!--edittttttttttttt tow phone-->
                </h1>
                <h1  class="phoneNumber">
                  <p dir="ltr"><i data-v-504c80e1="" class="bi bi-printer"></i>(+49) 53 41 - 79 06 86 3</p>
                  <!--<p>{{ DBitems.social.phone }}</p>-->
                  <!--edittttttttttttt tow phone-->
                </h1>
                <a :href="'mailto:info@can-grosshandel.de'">
                  <p><i data-v-504c80e1="" class="bi bi-envelope"></i>info@can-grosshandel.de</p>
                </a>
                <h1 v-if="!loading">
                  <p><i data-v-504c80e1="" class="bi bi-geo-alt"></i>Gustav Hagemann Straße 46, 38229 Salzgitter</p>
                  <!--<p>{{ DBitems.social.website }}</p>-->
                </h1>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-6 mb-1" style="margin-top: 30px">
            <!-- <div class="d-flex flex-row justify-content-around"> -->
            <div>
              <div class="contact-info">
                <h2 class="mb-4">ÖFFNUNGSZEITEN</h2>
                <h1 class="phoneNumber">
                  <p dir="ltr">Öffnungszeiten Großhandel:</p>
                  <!--<p>{{ DBitems.social.phone }}</p>-->
                  <!--edittttttttttttt tow phone-->
                </h1>
                <h1  class="phoneNumber">
                  <p dir="ltr">Mo –Fr  06.30–17.00 Uhr</p>
                  <!--<p>{{ DBitems.social.phone }}</p>-->
                  <!--edittttttttttttt tow phone-->
                </h1>
                <h1  class="phoneNumber">
                  <p dir="ltr">Samstag 06.30–14.00 Uhr</p>
                  <!--<p>{{ DBitems.social.phone }}</p>-->
                  <!--edittttttttttttt tow phone-->
                </h1>
              </div>
            </div>
          </div>
          <div class="container mobile-active">
            <hr />
          </div>
          <div class="col-lg-2 col-12 mb-1" style="margin-top: 30px">
            <!-- <div class="d-flex flex-row justify-content-around"> -->
            <div>
              <div class="contact-info">
                <h2 class="mb-4">Folge uns</h2>
                <div class="mt-1 social-linkes">
              <a href="https://instagram.com/can_grosshandel?igshid=MzRlODBiNWFlZA==">
                <!--<img  src="http://etrolley.net/websiteImages/instagram.svg" />-->
                <i class="bi bi-instagram"></i>
              </a>
              <a href="#">
                <!--<img  src="http://etrolley.net/websiteImages/facebook.svg" />-->
                <i class="bi bi-facebook"></i>
              </a>
            </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-12 ">
            <div class="copyRight text-center" style="margin-top: 20px">
              <p dir="ltr">Copyright CAN Großhandel GmbH & Co. KG in 2020. All Rights Reserved
</p>
            </div>
            
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import contactCard from "./contactCard.vue";

//import { KinesisContainer, KinesisElement} from 'vue-kinesis'
export default {
  // eslint-disable-next-line
  /*eslint-disable */
  name: "Footer",
  components: { contactCard },
  //components:{ KinesisContainer, KinesisElement },
  props: {
    // msg: String,
  },
  data: function() {
    return {
      //storeName: "markat",
      logo: "",
      DBitems: null,
      loading: false
    };
  },
  created() {
   
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
.DownloadImage:hover {
  transform: scale(1.05);
}
[dir="rtl"] .phoneNumber,
[dir="ltr"] .copyRight {
  text-align: end;
}
.DownloadDiv a {
  cursor: pointer;
}
a,
a:focus{
  color: #fff !important;
}
a:hover{
  color: #ffdda9 !important;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
</style>
