<template>
  <div class="container">
    <hooper
      style="
        height: 100%;
        max-height: 450px;
        margin-top: 50px;
        padding-top: 0px;
        padding-bottom: 0px;
      "
      :rtl="$i18n.locale == 'ar' ? true : false"
      :settings="hooperSettings"
    >
      <slide>
        <store-card
          :store_id=1
          store_image="/assets/image/Can/user.jpg"
          store_name="Ozgur Sarikaya"
          store_discription="Geschäftsführer"
          store_tel="Tel.: 0 53 41 - 836 47 12"
        ></store-card
        ><!--:store_name=  "`Ozgur Sarikaya`"-->
      </slide>
      <slide>
        <store-card
          :store_id=2
          store_image="/assets/image/Can/user.jpg"
          store_name="Ekin Sarikaya"
          store_discription="Geschäftsführer"
          store_tel="Tel.: 0 53 41 - 836 47 14"
        ></store-card
        ><!--:store_name= "`Ekin Sarikaya`"-->
      </slide>
      <slide>
        <store-card
          :store_id=3
          store_image="/assets/image/Can/user.jpg"
          store_name="Ali Can Sarikaya"
          store_discription="Manager"
          store_tel="Tel. 05341 - 83 647 21"
        ></store-card> </slide
      ><!--:store_name="`Ali Can Sarikaya`"-->
      <slide>
        <store-card
          :store_id=4
          store_image="/assets/image/Can/user.jpg"
          store_name="Kathrin Brandt"
          store_discription="Buchhaltung"
          store_tel="Tel.: 0 53 41 - 836 47 11"
        ></store-card
        ><!--:store_name= "`Kathrin Brandt`"-->
      </slide>
      <hooper-pagination slot="hooper-addons"></hooper-pagination>
      <!--<hooper-pagination slot="hooper-addons"></hooper-pagination>
    <hooper-progress slot="hooper-addons"></hooper-progress>-->
    </hooper>
  </div>
</template>

<script>
/* :store_id="item.product_id"
        :store_name="item.product_name"
        :store_link="item.product_name"
        :store_image="item.image"
        :store_discription="item.discount"*/
import storeCard from "./teamCard.vue";
import {
  Hooper,
  Slide,
  Progress as HooperProgress,
  Pagination as HooperPagination,
  Navigation as HooperNavigation,
} from "hooper";
import "hooper/dist/hooper.css";

export default {
  name: "App",
  props: { items: Array },
  components: {
    Hooper,
    Slide,
    HooperProgress,
    HooperPagination,
    HooperNavigation,
    storeCard,
  },
  data() {
    return {
      hooperSettings: {
        itemsToShow: 2,
        initialSlide: 0,
        itemsToSlide: 1,
        infiniteScroll: true,
        autoPlay: true,
        playSpeed: 3000,
        trimWhiteSpace: true,
        playSpeed: 2000,
        transition: 300,
        pagination: "fraction",

        wheelControl: false,

        breakpoints: {
          475: {
            itemsToShow: 3,
            itemsToSlide: 1,
          },
          768: {
            itemsToShow: 4,
          },
          /*992: {
            itemsToShow: 4
          },
          1200: {
            itemsToShow: 5,
          }
        }*/
        },
      },
    };
  },
};
</script>
<style scoped>
h1,
h4 {
  cursor: default;
}

a {
  text-decoration: none;
}

h1 {
  margin: 0;
  font-family: "Dosis", "Source Sans Pro", "Helvetica Neue", sans-serif;
  font-weight: 300;
  font-size: 4em;
  color: #2c3e50;
}

h4,
a {
  font-family: "Source Sans Pro", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #7f8c8d;
}

img.google {
  position: absolute;
  bottom: 0;
  left: 5px;
}
</style>
