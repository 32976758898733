<template>
  <div>
    <!--<div v-if="this.$store.state.langchange">{{ updatedata() }}</div>-->
    <!-- ======= Second Section ======= -->
    <section style="margin-top: 0px">
      <div class="container">
        <h4>CAN Großhandel GmbH & Co. KG</h4>
        <p style="text-align: center; margin: auto">
          Wir sind CAN, ein führendes Unternehmen in der Lebensmittelbranche
          seit 2003. Als langjähriger Partner für Restaurants, Hotels,
          Einzelhändler bieten wir eine breite Palette von Produkten, die von
          frischem Obst und Gemüse über Fleisch und Meeresfrüchte. Unser Ziel
          ist es, hochwertige Lebensmittel anzubieten und erstklassigen
          Kundenservice zu bieten.
        </p>
      </div>
    </section>
    <section style="margin-top: 0px">
      <div class="container">
        <h4>Unser Lieferradius</h4>
        <div class="row">
          <div class="col-lg-6">
            <div
              data-aos="fade-in"
              data-aos-duration="2000"
              data-aos-delay="400"
              class="colMobile"
              style="margin-top: 115px"
            >
              <p>
                Unsere Lieferungen erstrecken sich über einen Radius von 200
                Kilometern ausgehend von unserem Standort, um alle Kunden in
                diesem Bereich zu bedienen.
              </p>
              <!--<h4 class="FifthDescription">{{ $t('FifthDescription') }}</h4>
              <button class="btn">open google map</button>-->
            </div>
          </div>
          <div class="col-lg-6 colMobile" style="margin-top: 20px">
            <div
              data-aos="fade-in"
              data-aos-duration="2000"
              style="position: relative"
            >
              <img
                style="width: 66%; margin-right: 17%; margin-left: 17%"
                src="../../public/assets/image/Can/germany map2.png"
              />
              <img class="img2" src="../../public/assets/image/Can/KC01.png" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Second -->
  </div>
</template>
<script>
//import card1 from "../components/CardStyle1.vue";

export default {
  /*  components: {
    card1,
  },*/
  data() {
    return {};
  },
  created() {
    /*this.loading = true;
    //////////////////////home//////////////////////
    this.axios
      .get(this.$store.state.base_api_url + this.$store.storeName + "/home", {
        headers: {
          "Accept-Language": this.$i18n.locale,
        },
      })
      .then((response) => (this.DBitems = response.data.data.market))
      .then(() => (this.loading = false));*/
  },
  methods: {
    /*updatedata() {
      console.log("hooooooooooooooo");
      this.loading = true;
      //////////////////////home//////////////////////
      this.axios
        .get(this.$store.state.base_api_url + this.$store.storeName + "/home", {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        })
        .then((response) => (this.DBitems = response.data.data.market))
        .then(() => (this.loading = false));

      this.$store.state.langchange = false;
    },*/
  },
};
</script>
<style scoped>
section {
  padding-top: 80px;
  padding-bottom: 20px;
  color: var(--primary-color);
  font-family: "Kanit-Medium";
  min-height: 200px;
}
section h2 {
  font-family: "Kanit-Bold";
  font-size: 2em;
  width: 70%;
  line-height: 50px;
  background: linear-gradient(45deg, #7a7a7a, #343434, #7a7a7a);
  background-repeat: round;
  background-size: 80%;
  animation: animate 10s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

section h4 {
  /* width: 80%; */
  font-family: "Kanit-Medium";
  font-size: 2em;
  line-height: 50px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

section p {
  /*  width: 80%;*/
  font-family: "Kanit-Light";
  font-size: 20px;
  line-height: 35px;
  max-width: 90%;
  color: var(--secondary-color);
}
.btn {
  border: 2px solid var(--third-color);
  background-color: transparent;
  border-radius: 30px;
  font-size: 18px;
  font-family: "Kanit-Medium";
  color: var(--primary-color);
  font-weight: 700;
  text-decoration: none;
  z-index: 200;
  animation: animateBtn1 1s alternate infinite;
  text-align: center;
  margin-top: 20px;
  min-width: 150px;
}
.btn:hover {
  background-color: var(--third-color);
  color: #fff;
}
[dir="rtl"] .btn {
  padding: 10px 30px 10px 8px;
}
.btn i {
  font-size: 25px;
  background-color: #fff;
  border-radius: 50px;
  padding: 4px 14px;
  margin: 0 0 0 10px;
}
[dir="rtl"] .btn i {
  margin: 0 10px 0 0;
}
img {
  width: 100%;
  border-radius: 15px;
}

.img2 {
  width: 86%;
  position: absolute;
  top: 0;
  left: 39px;
  right: 0;
  animation: animateimage 5s ease-in-out infinite alternate;
}

@media (max-width: 992px) {
  .row {
    flex-direction: column-reverse;
  }
  section h4 {
    font-family: "Kanit-Bold";
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  section p {
    text-align: center;
    font-size: 14px;
    line-height: 15px;
    max-width: 100%;
  }
  .btn {
    width: 80%;
    text-align: center;
    padding: 10px 0 !important;
    margin: 10px 20%;
    width: 60%;
  }
  .colMobile[data-v-0582be57] {
    margin-top: 20px !important;
  }
}

@media (max-width: 1200px) {
}

@media (max-width: 630px) {
}

@media (max-width: 575px) {
  section {
    padding-top: 0px;
    padding-bottom: 20px;
  }
  section h4 {
    font-family: "Kanit-Bold";
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  section p {
    text-align: center;
    font-size: 14px;
    line-height: 15px;
    max-width: 100%;
  }
  .row {
    width: 96%;
    margin: auto;
    flex-direction: column-reverse;
  }
  .colMobile {
    margin-top: 20px !important;
  }

  .btn {
    padding: 10px 8px 10px 30px;
    font-size: 12px;
    font-weight: 700;
    margin-top: 10px;
  }
  .btn i {
    font-size: 15px;
    padding: 4px 10px;
  }
  .cd-headline b {
    line-height: 50px;
  }
}

@media (max-width: 400px) {
}
</style>
