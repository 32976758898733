<template>
  <router-link
    :to="{
          name: 'createAccount',
          params:{
            package_id: this.id
          }
        }"
  >
    <div class="card" data-aos="fade-up" data-aos-duration="2000">
      <div class="text-card" style="text-align: center; margin-top: 10px">
        <h5>{{ name }}</h5>
        <p>{{ description }}</p>
        <hr />
      </div>
      <div class="d-flex flex-row justify-content-center mb-3">
        <ul>
          <li
            v-for="(item,index) in features"
            :key="index"
            :class=" item.state ? 'trueMark' :'falseMark' "
          >
            <h6>{{ item.option }}</h6>
          </li>
        </ul>
      </div>
      <div>
        <h5>{{ price }} / {{ period }}</h5>
        <router-link
          class="btn"
          :to="{
                              name: 'createAccount',
                              params:{
                                package_id: this.id
                              }
                            }"
        >
          {{ $t("continue") }}
          <i
            :class="$i18n.locale == 'ar' ?'bi bi-arrow-left' : 'bi bi-arrow-right'"
          ></i>
        </router-link>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    id: Number,
    name: String,
    description: String,
    features: Array,
    price: String,
    period: String
  },
  data: function() {
    return {};
  }
};
</script>

<style scoped>
.card {
  border: 0;
  border-radius: 15px;
  width: 90%;
  margin: 0 5% 0 5%;
  padding: 30px 20px 5px;
  background-color: #fff;
  box-shadow: 0 0 15px #f0f0f0;
}
.card h5 {
  color: var(--primary-color);
  font-family: "Kanit-Bold";
  font-size: 18px;
}
.card p {
  color: var(--secondary-color);
  font-family: "Kanit-Medium";
  font-size: 13px;
}
.card h6 {
  color: #000;
  font-family: "Kanit-Medium";
  font-size: 12px;
  text-align: start;
  margin: 10px;
  height: 20px;
}

.btn {
  /*border: 2px solid var(--primary-color);*/
  background-color: var(--third-color);
  border-radius: 30px;
  padding: 5px 5px 7px 20px;
  font-size: 15px;
  font-family: "Kanit-Medium";
  color: var(--primary-color);
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 20px;
  text-decoration: none;
  z-index: 200;
}

.btn:hover {
  box-shadow: 0 0 20px var(--third-color);
  background-color: var(--third-color);
}
[dir="rtl"] .btn {
  padding: 5px 20px 7px 5px;
}
.btn i {
  font-size: 20px;
  margin: 0 10px;
  line-height: 35px;
}

.trueMark::marker {
  content: "✓";

  margin: 0 5px;
}
.falseMark::marker {
  content: "✘";
  color: red;
  margin: 0 5px;
}

@media (min-width: 1200px) {
}
/*@media (min-width: 1400px){
  
}

@media (min-width: 2000px){

}*/

@media (max-width: 575px) {
  .card {
    width: 100%;
    margin: auto;
    margin-bottom: 30px;
    padding: 10px 20px 5px;
  }
  .card h5 {
    font-size: 15px;
  }
  .card p {
    font-size: 11px;
  }
  .card h6 {
    font-size: 10px;
    margin: 5px;
  }
  ul {
    margin-bottom: 0;
  }
  [dir="rtl"] .btn {
    padding: 0px 10px 0px 5px;
  }
  .btn {
    padding: 0px 5px 0px 10px;
  }
}
</style>
