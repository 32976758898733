<template>
  <div style="min-height: 600px">
    <hero></hero>
    <fifth></fifth>
    <fourth></fourth>
    <!--<third></third>
    <fourth></fourth>-->
    <sixth-themes></sixth-themes>
    <second></second>
    <!--<sixth></sixth>-->
    <tenth></tenth>
    <seventh></seventh>
    <!--<eighth></eighth>
    <ninth></ninth>-->
   
  </div>
</template>
<script>
/*
header 
footer
responsive
buttons
fonts
seo */
/*durch unseren standpunkt in salzgitter können wir unsere kunden von 200 km schnell erreichen */
/*Unsere Struktur wurde für Sie erweitert. Neben einer großen Lagerhalle und neuen Büros entstand ein neuer Teil direkt gegenüber unserem bisherigem Standort. */
import hero from "../components/hero.vue";
import second from "../components/second.vue";
import third from "../components/third.vue";
import fourth from "../components/fourth.vue";
import fifth from "../components/fifth.vue";
import sixth from "../components/sixth.vue";
import sixthThemes from "../components/sixthThemes.vue";
import seventh from "../components/seventh.vue";
import eighth from "../components/eighth.vue";
import ninth from "../components/ninth.vue";
import tenth from "../components/tenth.vue";

export default {
    components: {
    hero,
    second,
    third,
    fourth,
    fifth,
    sixth,
    sixthThemes,
    seventh,
    eighth,
    ninth,
    tenth
  },
  data() {
    return {};
  },
  created() {
    /*this.loading = true;
    //////////////////////home//////////////////////
    this.axios
      .get(this.$store.state.base_api_url + this.$store.storeName + "/home", {
        headers: {
          "Accept-Language": this.$i18n.locale,
        },
      })
      .then((response) => (this.DBitems = response.data.data.market))
      .then(() => (this.loading = false));*/
  },
  methods: {
    /*updatedata() {
      console.log("hooooooooooooooo");
      this.loading = true;
      //////////////////////home//////////////////////
      this.axios
        .get(this.$store.state.base_api_url + this.$store.storeName + "/home", {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        })
        .then((response) => (this.DBitems = response.data.data.market))
        .then(() => (this.loading = false));

      this.$store.state.langchange = false;
    },*/
  }
};
</script>
<style scoped>

</style>
